import { Controller } from "@hotwired/stimulus"
import { getCurrentPositionAsync } from "../src/utils/current_position"
import { fetcher } from "../src/utils/fetcher";

// Connects to data-controller="area-search-modal"
export default class extends Controller {
  static targets = [
    "currentCoordinate",
    "currentCoordinateButton",
  ];

  connect() {}

  // アコーディオンメニューのアイコンを切り替える
  toggleRegionAccordionIcon(e) {
    const classNameIsOpen = "is_open";
    if (e.target.classList.contains(classNameIsOpen)) {
      e.target.classList.remove(classNameIsOpen);
    } else {
      e.target.classList.add(classNameIsOpen);
    }
  }

  // 現在地から取得した緯度経度を使用して検索ページ遷移する
  async getPositionAndSubmit(e) {
    // 送信をキャンセル
    e.preventDefault();

    // 全画面ローディング（full-screen-loadingコントローラーのものを使う）
    this.element["full-screen-loading"].submit();

    // 現在値情報を取得
    const position =  await getCurrentPositionAsync().catch(() => {
      // WebViewで開いている場合、currentAddressオブジェクトが定義されているため、FlutterのJavascriptChannelにメッセージを送る
      if (typeof currentAddress !== "undefined") {
        // eslint-disable-next-line no-undef
        currentAddress.postMessage(`["${this.currentCoordinateTarget.id}","${this.currentCoordinateButtonTarget.id}"]`);
      } else {
        alert("位置情報の利用が許可されていません。\n詳細は「位置情報の利用を許可」をご確認ください。");
        this.element["full-screen-loading"].hidden();
      }
      return null;
    });

    if (position === null) return;

    // 住所情報を取得
    const address = await this.#fetchAddress(position.coords.latitude, position.coords.longitude);

    // 検索ページへ遷移する
    const href = (address === null) ? e.target.href : `${e.target.href}?ken_id=${address?.kenId}&city_id=${address?.cityId}`;
    document.location.assign(href);
  }

  // 現在地から取得した緯度経度を使用して検索ページ遷移する（アプリ版）
  async getPositionAndSubmitFromWebview(e) {
    // Flutterでセットした緯度・経度
    const [latitude, longitude] = JSON.parse(this.currentCoordinateTarget.value).map((e) => Number(e));

    // 住所情報を取得
    const address = await this.#fetchAddress(latitude, longitude);

    // 検索ページへ遷移する
    const href = (address === null) ? e.params.searchUrl : `${e.params.searchUrl}?ken_id=${address?.kenId}&city_id=${address?.cityId}`;
    document.location.assign(href);
  }

  // 緯度・経度から住所情報を取得
  async #fetchAddress(latitude, longitude) {
    const result = await fetcher.get("/api/internal/v1/current_address", {
      params: { latitude: latitude, longitude: longitude }
    }).catch(() => null);

    return (result === null) ? null : result.data;
  }
}
