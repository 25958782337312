import { Controller } from "@hotwired/stimulus"
import { fetcher } from "../src/utils/fetcher";

const TOOLTIP_TEXT_ADD = 'お気に入りに追加';
const TOOLTIP_TEXT_REMOVE = 'お気に入りを解除';

// Connects to data-controller="bookmark-update"
export default class extends Controller {
  static targets = [
    "bookmarkButton",
    "bookmarkButtonText",
    "count",
    "tooltip",
  ];

  connect() {
    this.loading = false;
    if (this.#bookmarked()) {
      this.#setTooltipText(TOOLTIP_TEXT_REMOVE);
    } else {
      this.#setTooltipText(TOOLTIP_TEXT_ADD);
    }
  }

  // お気に入りの 登録 / 解除 をする
  async toggleBookmark(event) {
    if (this.loading) return;

    const shopId = event.params.shopId;

    if (this.#bookmarked()) {
      await this.#removeBookmark(shopId);
    } else {
      await this.#addBookmark(shopId);
    }
  }

  async #addBookmark(shopId) {
    let response;

    this.loading = true;

    try {
      response = await fetcher.post("/api/internal/v1/bookmarks?shop_id=" + shopId);
    } catch(e) {
      // ブラウザのコンソールにエラーログ & 詳細はバックエンドにログが出る
      this.loading = false;
      return;
    }
    this.loading = false;

    this.bookmarkButtonTargets.map(bookmarkButtonTarget => (bookmarkButtonTarget.classList.add("is_active")));
    this.countTargets.map(countTarget => (++countTarget.textContent));
    this.#setTooltipText(TOOLTIP_TEXT_REMOVE);
  }

  async #removeBookmark(shopId) {
    let response;

    this.loading = true;

    try {
      response = await fetcher.delete("/api/internal/v1/bookmarks?shop_id=" + shopId);
    } catch(e) {
      // ブラウザのコンソールにエラーログ & 詳細はバックエンドにログが出る
      this.loading = false;
      return;
    }
    this.loading = false;

    this.bookmarkButtonTargets.map(bookmarkButtonTarget => (bookmarkButtonTarget.classList.remove("is_active")));
    this.countTargets.map(countTarget => (--countTarget.textContent));
    this.#setTooltipText(TOOLTIP_TEXT_ADD);
  }

  #bookmarked() {
    return this.bookmarkButtonTarget.classList.contains("is_active");
  }

  #setTooltipText(text) {
    if (this.hasTooltipTarget) {
      this.tooltipTarget.textContent = text;
    }

    if (this.hasBookmarkButtonTextTarget) {
      this.bookmarkButtonTextTargets.map(bookmarkButtonTextTarget => (bookmarkButtonTextTarget.textContent = text));
    }
  }
}
