import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="draft-shop-save"
export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
  ];

  connect() {}

  submit() {
    this.#appendDraftField();
    this.element["full-screen-loading"].submit();
    this.formTarget.submit();
  }

  // draftFieldをformに追加する
  #appendDraftField() {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = 'draft';
    hiddenField.value = '';
    this.formTarget.appendChild(hiddenField);
  }
}
