import { Controller } from "@hotwired/stimulus";
import { fetcher } from "../src/utils/fetcher";

// Connects to data-controller="auto-select-address"
export default class extends Controller {
  static targets = ["autoSelectButton", "zip", "prefecture", "city", "townName", "errorMessage"];

  connect() {
    this.loading = false;
    if (!this.prefectureTarget.value) {
      this.cityTarget.disabled = true;
    }
    this.#disableAutoSelectButton();
    this.#fetchPrefectures();
  }

  changeZip() {
    this.#disableAutoSelectButton();
  }

  // 都道府県選択時の市区町村取得
  selectPrefecture(e) {
    const prefectureId = Number(e.currentTarget.value);
    this.#setCityOptions(prefectureId);
  }

  // 郵便番号で住所自動入力
  async autoSelectAddress() {
    // 自動入力ボタンを非活性化
    this.loading = true;
    this.#disableAutoSelectButton();

    // 無効化および選択項目をリセット
    this.#removeError();
    this.prefectureTarget.disabled = true;
    this.cityTarget.disabled = true;
    this.prefectureTarget.value = "";
    this.cityTarget.value = "";

    const response = await fetcher.get("/api/internal/v1/address_by_zip?zip=" + this.zipTarget.value);
    // 選択項目を再度有効化
    this.prefectureTarget.disabled = false;
    this.cityTarget.disabled = false;

    // エラー
    if (response.data.length === 0 || response.status !== 200) {
      this.errorMessageTarget.innerHTML = "住所が取得できませんでした。";
      this.errorMessageTarget.style.display = "block";
      // 自動入力ボタンを活性化
      this.loading = false;
      this.#disableAutoSelectButton();
      this.cityTarget.disabled = true;
      return;
    }

    const data = response.data[0];
    this.prefectureTarget.value = data.kenId;
    // 市区町村の選択肢を一度空にして全候補を挿入
    this.cityTarget.innerHTML = "";
    this.#setCityOptions(data.kenId);
    this.cityTarget.value = data.cityId;
    this.townNameTarget.value = data.townName;

    // 自動入力ボタンを活性化
    this.loading = false;
    this.#disableAutoSelectButton();
  }

  async #fetchPrefectures() {
    const result = await fetcher.get("/api/internal/v1/prefectures_with_cities");
    this.prefectures = result.data;
  }

  // 市区町村セレクトボックスにoptionタグを設定する
  /**
   * @param {Number} prefectureId
   */
   #setCityOptions(prefectureId) {
    const cities = this.prefectures.find((e) => e.kenId === prefectureId)?.cities ?? [];
    const fragment = document.createDocumentFragment();

    const defaultOption = document.createElement("option");
    defaultOption.textContent = "市区町村を選択";
    defaultOption.value = "";
    fragment.appendChild(defaultOption);

    cities.forEach((city) => {
      const option = document.createElement("option");
      option.value = city.cityId;
      option.textContent = city.cityName;
      fragment.appendChild(option);
    });

    this.cityTarget.innerHTML = "";
    this.cityTarget.appendChild(fragment);
    this.cityTarget.disabled = false;
  }

  // エラーメッセージおよび各入力欄のエラークラスを削除する
  #removeError() {
    this.errorMessageTarget.style.display = "none";
    this.prefectureTarget.classList.remove("is_error");
    this.cityTarget.classList.remove("is_error");
    this.townNameTarget.classList.remove("is_error");
  }

  // 自動入力ボタンを非活性にする
  #disableAutoSelectButton() {
    if (!this.zipTarget.value || !this.zipTarget.value.match(/^[0-9]{7}$/) || this.loading) {
      this.autoSelectButtonTarget.disabled = true;
      return;
    }
    this.autoSelectButtonTarget.disabled = false;
  }
}
