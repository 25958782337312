import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

// Connects to data-controller="submit-delete-request
export default class extends Controller {
  static targets = ["submitButton", "disableButton", "confirmModal", "completeModal", "errorMessage", "loading"];

  connect() {}

  async deleteStart() {
    this.#disableClose();
    this.loadingTarget.style.display = "block";
  }

  async deleteComplete(e) {
    if (e.detail.success) {
      this.confirmModalTarget.classList.remove("show");
      const completeModal = new bootstrap.Modal(this.completeModalTarget, {});
      completeModal.show();
      return;
    }

    // TODO: 例外発生時にレスポンスではなくrenderされてしまう
    this.errorMessageTarget.style.display = "block";
    this.loadingTarget.style.display = "none";
    this.#enableClose();
  }

  async #disableClose() {
    // 閉じるボタンを無効化
    this.disableButtonTargets.forEach((disableButton) => (disableButton.disabled = true));
    // TODO: 効かないので調査
    this.confirmModalTarget.dataset.bsBackdrop = "static";
    this.confirmModalTarget.dataset.bsKeyboard = false;
  }

  async #enableClose() {
    // 閉じるボタンを有効化
    this.disableButtonTargets.forEach((disableButton) => (disableButton.disabled = false));
    delete this.confirmModalTarget.dataset.bsBackdrop;
    delete this.confirmModalTarget.dataset.bsKeyboard;
  }
}
