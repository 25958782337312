import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

// Connects to data-controller="move-alert-link"
export default class extends Controller {
  static targets = ["moveAlertModal", "moveAlertLink"];

  async connect() {
    const isConfirm = location.pathname.indexOf("confirm");
    this.#moveAlertModal(isConfirm);
  }

  // 編集中または確認画面でaタグを押した際の確認モーダル制御
  // ページ内全aタグやフォーム関連のタグが制御できるようtarget指定したりcontroller設定するのは微妙なため、直接DOM操作している
  #moveAlertModal(isConfirm) {
    const self = this;
    self.changeForm = false;
    // フォームに変更があったかどうかの監視（入力画面のみ）
    if (!isConfirm) {
      document.querySelectorAll("input").forEach(function (button) {
        button.addEventListener("change", () => {
          self.changeForm = true;
        });
      });
      document.querySelectorAll("textarea").forEach(function (button) {
        button.addEventListener("change", () => {
          self.changeForm = true;
        });
      });
      document.querySelectorAll("select").forEach(function (button) {
        button.addEventListener("change", () => {
          self.changeForm = true;
        });
      });
    }

    const anchors = document.querySelectorAll("a");
    anchors.forEach((anchor) => {
      const target = anchor.getAttribute("target");
      // ウィンドウ内全てのaタグのうち、別窓で開くものと特定classのあるもの以外を制御
      if (target !== "_blank" && !anchor.classList.contains("evasionBtn")) {
        anchor.onclick = function (e) {
          // フォームに変更があった場合または確認画面ならリンクを無効化して確認モーダルを開く
          if (self.changeForm || isConfirm) {
            const targetLink = e.currentTarget.href; // 押されたリンクを取得
            e.preventDefault();
            self.moveAlertLinkTarget.setAttribute("href", targetLink);

            const completeModal = new bootstrap.Modal(self.moveAlertModalTarget, {});
            completeModal.show();
          }
        };
      }
    });
  }
}
