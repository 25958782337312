import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

// Connects to data-controller="url-addition"
export default class extends Controller {
  static targets = ["container", "template", "urlAdditionModal"];

  connect() {}

  addUrl() {
    // 最大6件まで追加
    if (this.templateTargets.length < 6) {
      const newUrlInput = this.templateTarget.cloneNode(true);
      newUrlInput.children[0].value = ""; // 空の状態で挿入されるように
      newUrlInput.children[0].classList.remove("is_error");
      this.containerTarget.append(newUrlInput);
      return;
    }
    const modal = new bootstrap.Modal(this.urlAdditionModalTarget, {});
    modal.show();
  }
}
