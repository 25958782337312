import { Controller } from "@hotwired/stimulus";
import imageCompression from "browser-image-compression";
import Sortable from "sortablejs";

// Connects to data-controller="image-file"
export default class extends Controller {
  static targets = [
    "dragDropArea",
    "dragDropBody",
    "fileInput",
    "previewArea",
    "previewList",
    "deleteButton",
    "sizeError",
    "typeError",
    "countError",
    "loading"
  ];

  initialize() {
    this.element[this.identifier] = this;
  }

  connect() {
    this.#controlDefaultDropArea();
    this.#filePreview();
    this.fileCount = this.previewListTargets.length; // ファイルの数バリデーション用
    // 店舗登録・編集と口コミで枚数制限を変える
    const currentPath = location.pathname;
    this.isShopImage = currentPath.includes("/new") || currentPath.includes("/edit");
    this.fileCountLimit = this.isShopImage ? 10 : 4;
    // 既存登録数が上限ならフォームを非表示
    if (this.fileCount >= this.fileCountLimit) {
      this.dragDropAreaTarget.style.display = "none";
    }

    Sortable.create(document.getElementById("previewArea"), {
      animation: 100,
      filter: ".file_removeBtn",
      onFilter: function (evt) {
        // イベントのアイテム（削除ボタンの親のli要素）を取得
        var itemToRemove = sortable.closest(evt.item); // get dragged item

        // アイテムが存在する場合、それをDOMから削除
        itemToRemove && itemToRemove.parentNode.removeChild(itemToRemove);
      },
    });
  }

  delete(e) {
    e.target.parentNode.remove();
    this.fileCount -= 1;
    // 削除後 上限枚数未満の場合ファイル選択エリアを再表示
    if (this.fileCount < this.fileCountLimit) {
      this.dragDropAreaTarget.style.display = "block";
    }
    // 口コミの場合、送信ボタン活性化判別
    if (!this.isShopImage) {
      this.disableReviewSubmitButton();
    }
  }

  // ファイル枚数を同期する（主に他Controllerから使用する処理）
  syncFileCount(count) {
    this.fileCount = count;
  }

  // 口コミモーダルの送信ボタン活性化判別
  disableReviewSubmitButton() {
    this.element["validate-review-input"].disableSubmitButton();
  }

  // ファイル枚数上限エラーを表示する
  showCountError() {
    this.countErrorTarget.style.display = "block";
  }

  // デフォルトの挙動を制御(drag)
  #controlDefaultDropArea() {
    const self = this;
    this.dragDropAreaTarget.addEventListener("dragover", function (e) {
      e.preventDefault();
      self.dragDropAreaTarget.classList.add("dragover");
    });
    this.dragDropAreaTarget.addEventListener("dragleave", function (e) {
      e.preventDefault();
      self.dragDropAreaTarget.classList.remove("dragover");
    });
    this.dragDropAreaTarget.addEventListener("drop", function (e) {
      e.preventDefault();
      self.dragDropAreaTarget.classList.remove("dragover");
    });
  }

  async #filePreview() {
    // ボタンを押下で写真を選択した時の制御
    this.fileInputTarget.addEventListener("change", async (e) => await this.#handleFile(e));
    // ドロップされた時の制御
    this.dragDropAreaTarget.addEventListener("drop", async (e) => await this.#handleFile(e));
  }

  // 選択・ドラッグ&ドロップのプレビューと反映処理
  async #handleFile(e) {
    this.#showLoading();
    e.preventDefault();

    const files = e.target.files ?? e.dataTransfer.files; // 選択したファイル全て取得(ドロップと選択両方対応)

    // アップロード取り消された場合処理なし
    if (!files.length) {
      this.#hideLoading();
      return;
    }

    // ファイル枚数超過時の警告
    if (this.fileCount + files.length > this.fileCountLimit) {
      this.showCountError();
      this.#hideLoading();
      return;
    }

    // 10枚になるとアップロードエリアを隠す
    if (this.fileCount + files.length >= this.fileCountLimit) {
      this.dragDropAreaTarget.style.display = "none";
    }

    // バリデーション
    this.#hideError();
    for (let k in Object.keys(files)) {
      // ファイル形式
      if (!files[k].type.match("jpg") && !files[k].type.match("jpeg") && !files[k].type.match("png")) {
        this.typeErrorTarget.style.display = "block";
        this.#hideLoading();
        return;
      }

      // ファイルサイズ
      if (files[k].size / 10000000 > 1) {
        this.sizeErrorTarget.style.display = "block";
        this.#hideLoading();
        return;
      }
    }

    // 複数回アップロード想定のため都度アップロードファイルの数を保持
    this.fileCount += files.length;

    // 圧縮
    const compressedFiles = await Promise.all(
      Object.keys(files).map(async (key) => {
        const file = files[key];
        return await imageCompression(file, { maxSizeMB: 1 });
      })
    );

    // 画像を処理
    this.#hideError();
    for (let i in Object.keys(compressedFiles)) {
      const file = compressedFiles[i];
      const reader = new FileReader();
      // サムネ出力
      const self = this;
      reader.onload = function () {
        self.#renderImage(reader.result);
      };

      reader.readAsDataURL(file);
    }

    this.#hideLoading();
  }

  #renderImage(src) {
    const imgTag = `<li data-image-file-target="previewList"><img src='${src}'><span class="file_removeBtn" data-image-file-target="deleteButton" data-action="click->image-file#delete">×削除する</span><input type="hidden" name="encoded_images[]" id="encoded_images[]" value="${src}"></li>`;
    this.previewAreaTarget.insertAdjacentHTML("beforeend", imgTag);
  }

  #showLoading() {
    this.dragDropBodyTarget.style.display = "none";
    this.loadingTarget.style.display = "block";
  }

  #hideLoading() {
    this.dragDropBodyTarget.style.display = "block";
    this.loadingTarget.style.display = "none";
  }

  #hideError() {
    this.sizeErrorTarget.style.display = "none";
    this.typeErrorTarget.style.display = "none";
    this.countErrorTarget.style.display = "none";
  }
}
