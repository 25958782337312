import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

// Connects to data-controller="toggle-header-menu"
export default class extends Controller {
  static targets = ["toggleButton", "menu"];

  connect() {
    const menu = new bootstrap.Collapse(this.menuTarget, {
      toggle: false // デフォルトは閉じておく
    });
    // 下記以外の動作はHTMLのBootstrapを利用
    document.addEventListener("click", (e) => {
      if (!e.target.closest("#" + this.menuTarget.id)) {
        // メニュー本体以外がクリックされたら閉じる
        menu.hide();
      }
      this.toggleClass();
    });
  }

  toggleClass() {
    if (this.toggleButtonTarget.classList.contains("collapsed")) {
      this.toggleButtonTarget.classList.remove("is_open");
      return;
    }
    this.toggleButtonTarget.classList.add("is_open");
  }
}
