import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="text-copy"
export default class extends Controller {
  static targets = ["copyButton", "copyText"];

  connect() {
    this.originButtonHTML = this.copyButtonTarget.innerHTML;
    const currentPath = location.pathname;
    this.completeButtonHTML = currentPath.includes("/shops")
      ? '<i class="fa-solid fa-check"></i>完了！'
      : '<i class="fa-solid fa-check"></i>コピー完了！';
  }

  copy() {
    if (typeof webViewClipboardInterface !== "undefined") {
      this.#copyOnWebView();
    } else if (!navigator.clipboard) {
      // iOS Safari等の非対応ブラウザの場合
      this.#copyOnNotSupportedBrowser();
    } else {
      this.#copyWithClipboard();
    }
  }

  #copyWithClipboard() {
    try {
      navigator.clipboard
        .writeText(this.copyTextTarget.innerText)
        .then(() => {
          this.#afterCopy();
        })
        .catch((error) => {
          console.error("Failed to copy text to clipboard:", error);
        });
    } catch (error) {
      console.error("Failed to copy text to clipboard");
      // エラーの際は別のメソッドを実行させる
      this.#copyOnNotSupportedBrowser();
    }
  }

  // 非表示要素から擬似的にユーザーによるコピー操作をさせる
  #copyOnNotSupportedBrowser() {
    const textarea = document.createElement("textarea");
    textarea.value = this.copyTextTarget.innerText;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    this.copyButtonTarget.parentNode.insertBefore(textarea, this.copyButtonTarget);
    textarea.focus();
    textarea.select();

    try {
      const result = document.execCommand("copy"); // 非推奨だが現状他に手段がないもよう
      if (result) {
        this.#afterCopy();
      } else {
        console.error("Failed to copy text to clipboard");
      }
    } catch (error) {
      console.error("Failed to copy text to clipboard:", error);
    }
    document.body.removeChild(textarea);
  }

  #afterCopy() {
    this.copyButtonTarget.innerHTML = this.completeButtonHTML;
    const self = this;
    setTimeout(function () {
      self.copyButtonTarget.innerHTML = self.originButtonHTML;
    }, 1500);
  }

  #copyOnWebView() {
    try {
      // eslint-disable-next-line no-undef
      webViewClipboardInterface.postMessage(this.copyTextTarget.innerText);
      this.#afterCopy();
      return true;
    } catch (e) {
      console.error("Failed to copy text to clipboard:", e);
      return false;
    }
  }
}
