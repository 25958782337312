import { Controller } from "@hotwired/stimulus";
import { fetcher } from "../src/utils/fetcher";

// Connects to data-controller="review-modal"
export default class extends Controller {
  static targets = [
    "editModalShopIdInput",
    "editModalReviewIdInput",
    "deleteModalShopIdInput",
    "deleteModalReviewIdInput",

    "reviewShopName",
    "reviewShopCategory",
    "reviewShopImage",
    "reviewShopUserIcon",
    "reviewShopUserIconStatus",
    "reviewShopUserName",
    "reviewComment",
    "reviewBudget",

    "errorMessageTemplate",
    "errorMessage",

    "reviewNewModal",
    "newModalCommentTitle",
    "newModalImageTitle",
    "newModalBudgetTitle",
    "newModalComment",
    "newModalBudget",

    "reviewEditModal",
    "editModalCommentTitle",
    "editModalImageTitle",
    "editModalBudgetTitle",
    "editModalShopName",
    "editModalShopAddress",
    "editModalShopCategory",
    "editModalShopImage",
    "editModalShopUserIcon",
    "editModalShopUserIconStatus",
    "editModalShopUserName",
    "editModalComment",
    "editModalCountNum",
    "editModalBudget",
    "editModalPreviewArea",
    "editModalImageTemplate",
    "editModalImage",
    "editModalEncodedImage",
    "editModalDragDropBody",
    "editModalDragDropArea",
    "editModalImageLoading",
    "editModalSubmitButton",
    "editModalHiddenMessage",
    "editModalOpenDeleteModalButton"
  ];

  connect() {
    // 更新成功のフラッシュメッセージ表示時のみ更新された口コミにis_updateを付与
    const flashSuccess = document.querySelector('.alert-success');
    if (flashSuccess && flashSuccess.innerText.includes("口コミの内容を更新しました")) {
      const moveLink = flashSuccess.querySelector('a');
      const result = moveLink.href.match(/#(.*)/);
      const reviewArticleId = result[1];
      const review = document.getElementById(reviewArticleId);
      review.classList.add("is_updated");
    }
  }

  // 編集モーダルの必要情報をセットする
  async setEditInput(e) {
    const shopId = e.params.payload.shopId;
    const reviewId = e.params.payload.reviewId;
    this.editModalShopIdInputTarget.value = shopId;
    this.editModalReviewIdInputTarget.value = reviewId;
    this.editModalPreviewAreaTarget.innerHTML = ""; // 写真リセット
    this.editModalShopUserIconStatusTarget.classList.remove(...this.editModalShopUserIconStatusTarget.classList);
    this.editModalShopUserNameTarget.classList.remove(...this.editModalShopUserNameTarget.classList);
    this.#showImageLoading();

    // 編集モーダル内の削除モーダルを開くボタンに必要な値をセット
    this.editModalOpenDeleteModalButtonTarget.dataset.reviewModalPayloadParam = `{"shopId": ${shopId}, "reviewId": "${reviewId}"}`;

    const review = document.getElementById(`review_${reviewId}`);
    // 運営非公開メッセージ
    if (review.classList.contains("is_restrict")) {
      this.editModalHiddenMessageTarget.style.display = "block";
    } else {
      this.editModalHiddenMessageTarget.style.display = "none";
    }
    // 店舗名
    const reviewShopNameElement = review.querySelector('[data-review-modal-target="reviewShopName"]');
    const shopName = reviewShopNameElement.innerHTML.trim();
    this.editModalShopNameTarget.textContent = shopName;
    this.editModalShopNameTarget.href = `/shops/${shopId}`;
    // 店舗住所
    const reviewShopAddressElement = review.querySelector('[data-review-modal-target="reviewShopAddress"]');
    const reviewShopAddress = reviewShopAddressElement.innerHTML.replace(/^（|）$/g, "");
    this.editModalShopAddressTarget.innerHTML = reviewShopAddress;
    // 店舗画像
    const reviewShopImageElement = review.querySelector('[data-review-modal-target="reviewShopImage"]');
    this.editModalShopImageTarget.src = reviewShopImageElement.src || reviewShopImageElement.content.querySelector('img').src;
    this.editModalShopImageTarget.alt = shopName;
    // ユーザー名
    const reviewShopUserNameElement = review.querySelector('[data-review-modal-target="reviewShopUserName"]');
    const userNameElement = reviewShopUserNameElement.tagName === 'TEMPLATE' ? reviewShopUserNameElement.content.querySelector('span') : reviewShopUserNameElement;
    this.editModalShopUserNameTarget.innerText = userNameElement.innerHTML.trim();
    this.editModalShopUserNameTarget.classList.add(...userNameElement.classList);
    // ユーザーランクアイコン
    const reviewShopUserIconStatusElement = review.querySelector('[data-review-modal-target="reviewShopUserIconStatus"]');
    const addClassList = reviewShopUserIconStatusElement.classList.length > 0 ? reviewShopUserIconStatusElement.classList : reviewShopUserIconStatusElement.content.querySelector('span').classList;
    this.editModalShopUserIconStatusTarget.classList.add(...addClassList);
    // ユーザーアイコン
    const reviewShopUserIconElement = review.querySelector('[data-review-modal-target="reviewShopUserIcon"]');
    this.editModalShopUserIconTarget.src = reviewShopUserIconElement.src || reviewShopUserIconElement.content.querySelector('img').src;
    // 口コミ本文
    const reviewCommentElement = review.querySelector('[data-review-modal-target="reviewComment"]');
    this.editModalCommentTarget.value = reviewCommentElement.innerText;
    this.editModalCountNumTarget.innerText = this.editModalCommentTarget.value.length;
    // 価格
    const reviewBudgetElement = review.querySelector('[data-review-modal-target="reviewBudget"]');
    const budget = reviewBudgetElement.innerText.replace("価格（1人あたり）：", "");
    this.editModalBudgetTarget.value = budget === "登録なし" ? "" : budget;

    // 写真
    const result = await fetcher.get(`/api/internal/v1/get_review_images?review_id=${reviewId}`);
    const reviewImages = result.data;
    reviewImages.forEach(reviewImage => {
      const clone = this.editModalImageTemplateTarget.content.cloneNode(true);
      const editModalImage = clone.querySelector('[data-review-modal-target="editModalImage"]');
      const editModalEncodedImage = clone.querySelector('[data-review-modal-target="editModalEncodedImage"]');
      editModalImage.src = reviewImage.url.thumb.url;
      editModalEncodedImage.value = reviewImage.base64;
      this.editModalPreviewAreaTarget.appendChild(clone);
    });
    this.#hideImageLoading();
    // ファイル枚数をimage-controllerに同期
    this.element["image-file"].syncFileCount(reviewImages.length);
    // 写真4枚ならドラッグドロップエリアを非表示
    if (reviewImages.length >= 4) {
      this.editModalDragDropAreaTarget.style.display = "none";
    } else {
      this.editModalDragDropAreaTarget.style.display = "block";
    }
    if (reviewImages.length > 4) {
      this.editModalSubmitButtonTarget.disabled = true;
      this.element["image-file"].showCountError();
      return;
    }
    this.editModalSubmitButtonTarget.disabled = false;
  }

  // 削除モーダルの必要情報をセットする
  setDeleteInput(e) {
    this.deleteModalShopIdInputTarget.value = e.params.payload.shopId;
    this.deleteModalReviewIdInputTarget.value = e.params.payload.reviewId;
  }

  #showImageLoading() {
    this.editModalDragDropBodyTarget.style.display = "none";
    this.editModalImageLoadingTarget.style.display = "block";
  }

  #hideImageLoading() {
    this.editModalDragDropBodyTarget.style.display = "block";
    this.editModalImageLoadingTarget.style.display = "none";
  }

  async submitStart() {
    // 全画面ローディング（full-screen-loadingコントローラーのものを使う）
    this.element["full-screen-loading"].submit();
    // エラー表示をリセット
    this.errorMessageTargets.forEach(target => target.style.display = "none");
    if (location.pathname.startsWith("/shops/")) {
      this.newModalCommentTarget.classList.remove("is_error");
      this.newModalBudgetTarget.classList.remove("is_error");
    }
    this.editModalCommentTarget.classList.remove("is_error");
    this.editModalBudgetTarget.classList.remove("is_error");
  }

  async createComplete(e) {
    const response = e.detail.fetchResponse.response;
    if (response.ok) {
      location.href = location.origin + location.pathname;
      return;
    }

    const data = await response.json();
    const errors = data.errors;
    if (errors.hasOwnProperty("other")) {
      // その他エラー時はフォーム上ではなく汎用フラッシュメッセージを表示
      location.href = location.origin + location.pathname;
      return;
    }

    // 全画面ローディング解除（full-screen-loadingコントローラーのものを使う）
    this.element["full-screen-loading"].hidden();

    if (Object.keys(errors).length > 0) {
      // エラーがある場合は上部へスクロール
      this.reviewNewModalTarget.scroll({top: 0, behavior: 'smooth'});
    }
    for (const key of Object.keys(errors)) {
      const clone = this.errorMessageTemplateTarget.content.cloneNode(true);
      const errorElement = clone.querySelector('[data-review-modal-target="errorMessage"]');
      errors[key].forEach(errorMessage => {
        errorElement.innerText = errorMessage;
        errorElement.style.display = "block";
        if (key === "comment") {
          this.newModalCommentTitleTarget.appendChild(errorElement);
          this.newModalCommentTarget.classList.add("is_error");
          return;
        }
        if (key === "image") {
          this.newModalImageTitleTarget.appendChild(errorElement);
          return;
        }
        if (key === "budget") {
          this.newModalBudgetTitleTarget.appendChild(errorElement);
          this.newModalBudgetTarget.classList.add("is_error");
          return;
        }
      });
    }
  }

  async editComplete(e) {
    const response = e.detail.fetchResponse.response;
    if (response.ok) {
      location.href = location.origin + location.pathname;
      return;
    }
    const data = await response.json();
    const errors = data.errors;
    if (errors.hasOwnProperty("other")) {
      // その他エラー時はフォーム上ではなく汎用フラッシュメッセージを表示
      location.href = location.origin + location.pathname;
      return;
    }

    // 全画面ローディング解除（full-screen-loadingコントローラーのものを使う）
    this.element["full-screen-loading"].hidden();

    if (Object.keys(errors).length > 0) {
      // エラーがある場合は上部へスクロール
      this.reviewEditModalTarget.scroll({top: 0, behavior: 'smooth'});
    }
    for (const key of Object.keys(errors)) {
      const clone = this.errorMessageTemplateTarget.content.cloneNode(true);
      const errorElement = clone.querySelector('[data-review-modal-target="errorMessage"]');
      errors[key].forEach(errorMessage => {
        errorElement.innerText = errorMessage;
        errorElement.style.display = "block";
        if (key === "comment") {
          this.editModalCommentTitleTarget.appendChild(errorElement);
          this.editModalCommentTarget.classList.add("is_error");
          return;
        }
        if (key === "image") {
          this.editModalImageTitleTarget.appendChild(errorElement);
          return;
        }
        if (key === "budget") {
          this.editModalBudgetTitleTarget.appendChild(errorElement);
          this.editModalBudgetTarget.classList.add("is_error");
          return;
        }
      });
    }
  }
}
