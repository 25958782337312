import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="user-report-modal"
export default class extends Controller {
  static targets = ["cancelButton", "form", "loading", "submitButton"];

  connect() {
    this.submitButtonTarget.disabled = true;
  }

  async submit() {
    this.loadingTarget.style.display = "block";
    this.cancelButtonTarget.disabled = true;
    this.submitButtonTarget.disabled = true;

    this.formTarget.requestSubmit();
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
  }
}
