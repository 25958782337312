import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="validate-shop-input"
export default class extends Controller {
  static targets = [
    /* 各入力欄 */
    "submitButton",
    "comment",

    /* 文字数カウント関連 */
    "countNum",
    "countField",
    "countError",

    /* 画像枚数 */
    "reviewImages"
  ];

  initialize() {
    this.element[this.identifier] = this;
  }

  async connect() {
    await new Promise((resolve) => setTimeout(resolve, 100)); // 読み込みが遅れる場合があるので対策
    this.disableSubmitButton();
  }

  // 文字数カウント
  countInput() {
    const length = this.countFieldTarget.value.length;
    this.countNumTarget.textContent = length;
    if (length < 20 || length > 500) {
      this.countErrorTarget.textContent = length < 20 ? "20文字以上入力してください。" : "500文字以内で入力してください。";
      this.countErrorTarget.style.display = "block";
      this.countFieldTarget.classList.add("is_error");
      this.disableSubmitButton();
      return;
    }
    this.countErrorTarget.style.display = "none";
    this.countFieldTarget.classList.remove("is_error");
    this.disableSubmitButton();
  }

  // 未入力またはエラーありなら送信ボタンを非活性にする
  disableSubmitButton() {
    this.submitButtonTarget.disabled = !this.countFieldTarget.value || this.countFieldTarget.classList.contains("is_error") || this.reviewImagesTarget.getElementsByTagName("li").length > 4;
  }
}
