import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="validate-shop-input"
export default class extends Controller {
  static targets = [
    /* 各入力欄 */
    "submitButton",
    "shopOwner",
    "shopStaff",
    "shopWorking",
    "shopNotWorking",
    "shopName",
    "shopZip",
    "shopKenId",
    "shopCityId",
    "shopTownText",
    "shopCategory",
    "shopImages",
    "shopBusinessHours",
    "shopHoliday",
    "shopWorkingHours",
    "shopBudgetMin",
    "shopBudgetMax",
    "shopDescription",
    "shopUrl",
    "checkRule", // 利用規約

    /* ラベル */
    "shopOwnerLabel",
    "shopStaffLabel",
    "shopWorkingLabel",
    "shopNotWorkingLabel",

    /* セクション */
    "shopIsWorkingSection",
    "shopWorkingHoursSection",

    /* 入力グループ */
    "addressGroup",
    "businessHoursGroup",
    "budgetGroup",

    /* エラーテキスト表示箇所 */
    "errorTopMessage",
    "errorShopIsOwner",
    "errorShopIsWorking",
    "errorShopName",
    "errorShopAddress",
    "errorShopCategory",
    "errorShopImages",
    "errorShopBusinessHours",
    "errorShopWorkingHours",
    "errorShopBudget",
    "errorShopDescription",
    "errorShopUrl",

    /* 文字数カウント関連 */
    "countNum",
    "countField",
    "countError"
  ];

  async connect() {
    await new Promise((resolve) => setTimeout(resolve, 100)); // 読み込みが遅れる場合があるので対策
    this.#disableSubmitButton();
    this.countInput();

    this.budgetSelected = this.shopBudgetMinTarget.value && this.shopBudgetMaxTarget.value;
    this.firstSelectBudget = null;
  }

  changeCheckRule() {
    this.#disableSubmitButton();
  }

  changeInput(e) {
    const targetName = e.params.payload.target;
    const groupName = e.params.payload.group ? e.params.payload.group : e.params.payload.target;
    this.#checkError(targetName, groupName);
  }

  // 「店舗・施設を経営しているか」ラジオボタンのフォーカス
  radioFocusShopIsOwner() {
    // 経営している場合
    if (this.shopOwnerTarget.checked) {
      // 選択時のスタイル変更
      this.shopOwnerLabelTarget.classList.remove("is_uncheck");
      this.shopStaffLabelTarget.classList.add("is_uncheck");

      // 「週1日以上 店頭にいるか」セクションの表示
      this.shopIsWorkingSectionTarget.classList.remove("is_hide");

      // 「週1日以上 店頭にいるか」ラジオボタンのフォーカス
      // はいの場合
      if (this.shopWorkingTarget.checked) {
        // 「店頭にいる日時」セクションの表示
        this.shopWorkingHoursSectionTarget.classList.remove("is_hide");
      } else {
        // 「店頭にいる日時」セクションの非表示
        this.shopWorkingHoursSectionTarget.classList.add("is_hide");
      }
    }

    // スタッフの場合
    if (this.shopStaffTarget.checked) {
      // 選択時のスタイル変更
      this.shopOwnerLabelTarget.classList.add("is_uncheck");
      this.shopStaffLabelTarget.classList.remove("is_uncheck");

      // 「週1日以上 店頭にいるか」セクションの非表示
      this.shopIsWorkingSectionTarget.classList.add("is_hide");
      // 「店頭にいる日時」セクションの表示
      this.shopWorkingHoursSectionTarget.classList.remove("is_hide");
    }
  }

  // 「週1日以上 店頭にいるか」ラジオボタンのフォーカス
  radioFocusShopIsWorking() {
    // はいの場合
    if (this.shopWorkingTarget.checked) {
      // 選択時のスタイル変更
      this.shopWorkingLabelTarget.classList.remove("is_uncheck");
      this.shopNotWorkingLabelTarget.classList.add("is_uncheck");

      // 「店頭にいる日時」セクションの表示
      this.shopWorkingHoursSectionTarget.classList.remove("is_hide");
    }

    // いいえの場合
    if (this.shopNotWorkingTarget.checked) {
      // 選択時のスタイル変更
      this.shopWorkingLabelTarget.classList.add("is_uncheck");
      this.shopNotWorkingLabelTarget.classList.remove("is_uncheck");

      // 「店頭にいる日時」セクションの非表示
      this.shopWorkingHoursSectionTarget.classList.add("is_hide");
    }
  }

  // URL形式をチェック
  validateUrl() {
    // 全URL入力欄をチェック
    this.shopUrlTargets.forEach((shopUrlTarget) => {
      const pattern = /^(https?:\/\/[\-\w./?,=#:@\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+)$/;
      if (shopUrlTarget.value && !pattern.test(shopUrlTarget.value)) {
        shopUrlTarget.classList.add("is_error");
        return;
      }
      shopUrlTarget.classList.remove("is_error");
    });
    // 1件でもエラーがあればメッセージを表示する
    if (this.shopUrlTargets.some((shopUrlTarget) => shopUrlTarget.classList.contains("is_error"))) {
      this.errorShopUrlTarget.style.display = "block";
      return false;
    }
    this.errorShopUrlTarget.style.display = "none";
    return true;
  }

  // 文字数カウント
  countInput() {
    const length = this.countFieldTarget.value.length;
    this.countNumTarget.textContent = 20 - length;
    if (length > 20) {
      this.countErrorTarget.style.display = "block";
      this.countNumTarget.classList.add("is_error");
      this.countFieldTarget.classList.add("is_error");
      return;
    }
    this.countErrorTarget.style.display = "none";
    this.countNumTarget.classList.remove("is_error");
    this.countFieldTarget.classList.remove("is_error");
  }

  // 予算選択時、矛盾が生じないように選択肢をdisableにする
  disableBudgetSelect(e) {
    const targetName = e.params.payload.target;
    const otherTargetName = targetName === "shopBudgetMin" ? targetName : "shopBudgetMax";
    const options = targetName === "shopBudgetMin" ? [...this.shopBudgetMaxTarget.options] : [...this.shopBudgetMinTarget.options];
    const target = targetName === "shopBudgetMin" ? this.shopBudgetMinTarget : this.shopBudgetMaxTarget;
    const otherTarget = targetName === "shopBudgetMin" ? this.shopBudgetMaxTarget : this.shopBudgetMinTarget;
    const needsReset = targetName === "shopBudgetMin" ? Number(target.value) > Number(otherTarget.value) : target.value && otherTarget.value && Number(target.value) < Number(otherTarget.value);

    if (!this.firstSelectBudget) {
      // 下限・上限どちらが最初に選択されたか保持
      this.firstSelectBudget = targetName;
    }
    if (this.budgetSelected && needsReset) {
      // 選択済みの状態で矛盾する選択肢を選んだ場合、逆のselectをリセットする
      otherTarget.value = "";
      this.budgetSelected = false;
      this.firstSelectBudget = otherTargetName;
    }

    // 選択に応じて下限・上限が矛盾する選択肢を無効化（最初に選択した側は無効化しない）
    options.forEach(option => {
      const needsDisable = targetName === "shopBudgetMin" ? target.value && option.value && Number(target.value) > Number(option.value) : target.value && option.value && Number(target.value) < Number(option.value);
      if (needsDisable && (!otherTarget.value || targetName === this.firstSelectBudget)) {
        option.disabled = true;
        return;
      };
      option.disabled = false;
    });
    // 選択済み判定
    this.budgetSelected = !(!target.value || !otherTarget.value);
  }

  submit(e) {
    let hasError = false;
    let message;

    // 店舗・施設を経営しているか：未入力
    if (!this.shopOwnerTarget.checked && !this.shopStaffTarget.checked) {
      e.preventDefault(); // 送信をキャンセル
      hasError = true;
      message = "選択してください。";
      this.#switchErrorMessage(true, this.errorShopIsOwnerTarget, message);
    }

    // 経営者 かつ 週1日以上 店頭にいるか：未入力
    if (this.shopOwnerTarget.checked && !this.shopWorkingTarget.checked && !this.shopNotWorkingTarget.checked) {
      e.preventDefault(); // 送信をキャンセル
      hasError = true;
      message = "選択してください。";
      this.#switchErrorMessage(true, this.errorShopIsWorkingTarget, message);
    }

    // 店舗・施設の名前：未入力
    if (!this.shopNameTarget.value) {
      e.preventDefault(); // 送信をキャンセル
      hasError = true;
      message = "入力してください。";
      this.#switchErrorMessage(true, this.errorShopNameTarget, message);
      this.shopNameTarget.value || this.#switchErrorClass(true, "shopName");
    }

    // 店舗・施設の名前：21字以上
    if (this.shopNameTarget.value && this.shopNameTarget.length > 20) {
      e.preventDefault(); // 送信をキャンセル
      hasError = true;
      this.countInput();
    }

    // 店舗・施設の住所：未入力
    if (
      !this.shopZipTarget.value ||
      !this.shopKenIdTarget.value ||
      !this.shopCityIdTarget.value ||
      !this.shopTownTextTarget.value
    ) {
      e.preventDefault();
      hasError = true;
      message = "入力してください。";
      this.#switchErrorMessage(true, this.errorShopAddressTarget, message);
      this.shopZipTarget.value || this.#switchErrorClass(true, "shopZip");
      this.shopKenIdTarget.value || this.#switchErrorClass(true, "shopKenId");
      this.shopCityIdTarget.value || this.#switchErrorClass(true, "shopCityId");
      this.shopTownTextTarget.value || this.#switchErrorClass(true, "shopTownText");
    }

    // 郵便番号：半角英数7字以外
    if (this.shopZipTarget.value && !this.shopZipTarget.value.match(/^[0-9]{7}$/)) {
      message = "郵便番号は7桁の半角英数字で入力してください。";
      this.#switchErrorMessage(true, this.errorShopAddressTarget, message);
      this.#switchErrorClass(true, "shopZip");
    }

    // カテゴリー：未入力
    if (!this.shopCategoryTarget.value) {
      e.preventDefault();
      hasError = true;
      message = "入力してください。";
      this.#switchErrorMessage(true, this.errorShopCategoryTarget, message);
      this.shopCategoryTarget.value || this.#switchErrorClass(true, "shopCategory");
    }

    // // 店舗・施設写真: 未入力
    if (!this.shopImagesTarget.getElementsByTagName("li").length) {
      e.preventDefault();
      hasError = true;
      message = "写真をアップロードしてください。"
      this.#switchErrorMessage(true, this.errorShopImagesTarget, message);
    }

    // // 店舗・施設写真: 上限超え
    if (this.shopImagesTarget.getElementsByTagName("li").length > 10) {
      e.preventDefault();
      hasError = true;
      message = "写真の最大枚数は10枚までです。"
      this.#switchErrorMessage(true, this.errorShopImagesTarget, message);
    }

    // 営業時間・定休日：未入力
    if (!this.shopBusinessHoursTarget.value || !this.shopHolidayTarget.value) {
      e.preventDefault();
      hasError = true;
      message = "入力してください。";
      this.#switchErrorMessage(true, this.errorShopBusinessHoursTarget, message);
      this.shopBusinessHoursTarget.value || this.#switchErrorClass(true, "shopBusinessHours");
      this.shopHolidayTarget.value || this.#switchErrorClass(true, "shopHoliday");
    }

    // 営業時間・定休日：3000字超え
    if (this.shopBusinessHoursTarget.value.length > 3000 || this.shopHolidayTarget.value.length > 3000) {
      e.preventDefault();
      hasError = true;
      message = "3000字以内で入力してください。";
      this.#switchErrorMessage(true, this.errorShopBusinessHoursTarget, message);
      this.shopBusinessHoursTarget.value <= 3000 || this.#switchErrorClass(true, "shopBusinessHours");
      this.shopHolidayTarget.value <= 3000 || this.#switchErrorClass(true, "shopHoliday");
    }

    // 店頭にいる日時：未入力
    // -> 経営者で週に１回以上店頭にいない場合を除く
    if (!(this.shopOwnerTarget.checked && this.shopNotWorkingTarget.checked) && !this.shopWorkingHoursTarget.value) {
      e.preventDefault();
      hasError = true;
      message = "入力してください。";
      this.#switchErrorMessage(true, this.errorShopWorkingHoursTarget, message);
      this.shopWorkingHoursTarget.value || this.#switchErrorClass(true, "shopWorkingHours");
    }

    // 店頭にいる日時：3000字超え
    // -> 経営者で週に１回以上店頭にいない場合を除く
    if (!(this.shopOwnerTarget.checked && this.shopNotWorkingTarget.checked) && this.shopWorkingHoursTarget.value.length > 3000) {
      e.preventDefault();
      hasError = true;
      message = "3000字以内で入力してください。";
      this.#switchErrorMessage(true, this.errorShopWorkingHoursTarget, message);
      this.shopWorkingHoursTarget.value || this.#switchErrorClass(true, "shopWorkingHours");
    }

    // 予算：未入力
    if (!this.shopBudgetMinTarget.value || !this.shopBudgetMaxTarget.value) {
      e.preventDefault();
      hasError = true;
      message = "選択してください。";
      this.#switchErrorMessage(true, this.errorShopBudgetTarget, message);
      this.shopBudgetMinTarget.value || this.#switchErrorClass(true, "shopBudgetMin");
      this.shopBudgetMaxTarget.value || this.#switchErrorClass(true, "shopBudgetMax");
    }

    // どんな店舗・施設：50字未満
    if (!this.shopDescriptionTarget.value || this.shopDescriptionTarget.value.length < 50) {
      e.preventDefault();
      hasError = true;
      message = "50字以上で入力してください。";
      this.#switchErrorMessage(true, this.errorShopDescriptionTarget, message);
      this.#switchErrorClass(true, "shopDescription");
    }

    // どんな店舗・施設：3000字超え
    if (this.shopDescriptionTarget.value.length > 3000) {
      e.preventDefault();
      hasError = true;
      message = "3000字以内で入力してください。";
      this.#switchErrorMessage(true, this.errorShopDescriptionTarget, message);
      this.#switchErrorClass(true, "shopDescription");
    }

    // URL：形式が異なる
    if (this.shopUrlTargets.some((shopUrlTarget) => shopUrlTarget.value && !this.validateUrl())) {
      e.preventDefault();
      hasError = true;
    }

    if (!hasError) {
      // 全画面ローディング（full-screen-loadingコントローラーのものを使う）
      this.element["full-screen-loading"].submit();
      return;
    }

    // トップのエラーメッセージを表示
    window.scroll({ top: 0, behavior: "smooth" });
    if (!document.getElementById("error_top_rails")) {
      this.errorTopMessageTarget.style.display = "inline-block";
    }
  }

  // 利用規約が未チェックなら送信ボタンを非活性にする
  #disableSubmitButton() {
    this.submitButtonTarget.disabled = !this.checkRuleTarget.checked;
  }

  // エラー表示の切り替え
  #checkError(targetName, groupName) {
    // 利用規約周りはメッセージやclass付与は不要なのでreturn
    if (targetName === "checkRule") return;

    // 頭文字を大文字にする
    const capitalized = groupName.charAt(0).toUpperCase() + groupName.slice(1);
    const errorDisplayTarget = this[`error${capitalized}Target`];

    const inputElement = this[`${targetName}Target`];
    let isShowError;
    let message;
    let isAddErrorClass;

    // 店舗・施設を経営しているか 未入力
    if (groupName === "shopIsOwner") {
      message = "選択してください。";
      isAddErrorClass = !this.shopOwnerTarget.checked && !this.shopStaffTarget.checked;
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      return;
    }

    // 週1日以上 店頭にいるか 未入力
    if (groupName === "shopIsWorking") {
      message = "選択してください。";
      isAddErrorClass = !this.shopWorkingTarget.checked && !this.shopNotWorkingTarget.checked;
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      return;
    }

    // 店舗名 未入力
    if (targetName === "shopName") {
      isShowError = !this.shopNameTarget.value;
      message = "入力してください。";
      isAddErrorClass = !this.shopNameTarget.value || this.countFieldTarget.value.length > 20;
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      this.#switchErrorClass(isAddErrorClass, targetName);
      return;
    }

    // 住所関連全般 未入力
    if (groupName === "shopAddress") {
      // 1つでも未入力かつis_errorクラスのものがある または 入力中のものが未入力
      isShowError =
        this.addressGroupTargets.some((target) => {
          return target.classList.contains("is_error") && !target.value;
        }) || !inputElement.value;
      message = "入力してください。";
      isAddErrorClass = !inputElement.value;

      if (targetName === "shopZip" && this.shopZipTarget.value) {
        // 郵便番号 半角英数
        isShowError = !this.shopZipTarget.value.match(/^[0-9]{7}$/);
        message = "郵便番号は7桁の半角英数字で入力してください。";
        isAddErrorClass = isShowError;
      }
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      this.#switchErrorClass(isAddErrorClass, targetName);
      return;
    }

    // カテゴリ 未入力
    if (targetName === "shopCategory") {
      isShowError = !this.shopCategoryTarget.value;
      message = "選択してください。";
      isAddErrorClass = !inputElement.value;
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      this.#switchErrorClass(isAddErrorClass, targetName);
      return;
    }

    // 営業時間・定休日 未入力 or 3000字超え
    if (groupName === "shopBusinessHours") {
      // 1つでも未入力かつis_errorクラスのものがある
      // または 入力中のものが未入力
      // または 入力中のものが3000字を超えている
      isShowError =
        this.businessHoursGroupTargets.some((target) => {
          return target.classList.contains("is_error") && !target.value;
        })
        || !inputElement.value
        || inputElement.value.length > 3000;
      message = "入力してください。";
      if (inputElement.value.length > 3000) {
        message = "3000字以内で入力してください。";
      }
      isAddErrorClass = !inputElement.value || inputElement.value.length > 3000;
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      this.#switchErrorClass(isAddErrorClass, targetName);
      return;
    }

    // 店頭にいる日時 未入力 or 3000字超え
    if (targetName === "shopWorkingHours") {
      // 「週1日以上 店頭にいるか」のチェックがある場合にエラー判定する
      isShowError = !(this.shopOwnerTarget.checked && this.shopNotWorkingTarget.checked) && (!this.shopWorkingHoursTarget.value || this.shopWorkingHoursTarget.value.length > 3000);
      message = "入力してください。";
      if (this.shopWorkingHoursTarget.value.length > 3000) {
        message = "3000字以内で入力してください。";
      }
      isAddErrorClass = !inputElement.value || inputElement.value.length > 3000;
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      this.#switchErrorClass(isAddErrorClass, targetName);
      return;
    }

    // 予算 未入力
    if (groupName === "shopBudget") {
      // 1つでも未入力かつis_errorクラスのものがある
      // または 入力中のものが未入力
      isShowError =
        this.budgetGroupTargets.some((target) => {
          return target.classList.contains("is_error") && !target.value;
        })
        || !inputElement.value;
      message = "入力してください。";
      isAddErrorClass = !inputElement.value;
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      this.#switchErrorClass(isAddErrorClass, targetName);
      return;
    }

    // どんな店舗・施設 50字未満 or 3000字超え
    if (targetName === "shopDescription") {
      isShowError = inputElement.value.length < 50 || inputElement.value.length > 3000;
      message = "50字以上で入力してください。";
      if (inputElement.value.length > 3000) {
        message = "3000字以内で入力してください。";
      }
      this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
      this.#switchErrorClass(isShowError, targetName);
      return;
    }

    // その他未入力
    isShowError = !this[`${targetName}Target`].value;
    message = "入力してください。";
    isAddErrorClass = !inputElement.value;
    this.#switchErrorMessage(isShowError, errorDisplayTarget, message);
    this.#switchErrorClass(isAddErrorClass, targetName);
  }

  // エラーメッセージの表示切り替え
  #switchErrorMessage(isShow, errorElement, message) {
    if (isShow) {
      errorElement.style.display = "inline";
      errorElement.innerHTML = message;
    } else {
      errorElement.style.display = "none";
    }
  }

  // エラークラスの付与切り替え
  #switchErrorClass(isAddClass, targetName) {
    const inputElement = this[`${targetName}Target`];
    if (isAddClass) {
      inputElement.classList.add("is_error");
    } else {
      inputElement.classList.remove("is_error");
    }
  }
}
