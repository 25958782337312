import { Controller } from "@hotwired/stimulus";
import { Collapse, Modal } from "bootstrap";
import { fetcher } from "../src/utils/fetcher";
import { getCurrentPositionAsync } from "../src/utils/current_position"

export default class extends Controller {
  static targets = [
    "form",
    "searchArea",
    "searchOptionArea",
    "toggleSearchOptionAreaButtonPc",
    "textField",
    "areaSelect",
    "prefectureSelectPc",
    "citySelectPc",
    "prefectureSelectSp",
    "citySelectSp",
    "categorySelectPc",
    "categorySelectSp",
    "budgetMinSelectPc",
    "budgetMinSelectSp",
    "budgetMaxSelectPc",
    "budgetMaxSelectSp",
    "rankRadio", // PC・SP共通
    "workingCheckPc",
    "workingCheckSp",
    "ownerCheckPc",
    "ownerCheckSp",
    "sortSelectPc",
    "sortSelectSp",
    "submitButton",
    "submitButtonText",
    "selectPcCollapse",
    "filterSpButton",
    "filterSpModal",
    "submitFilterSpButton", // 全体の絞り込みボタン(SP)
    "resetAllConditionsWithSubmitButton",
    "searchCountData",
    "searchCountDisplayPc",
    "searchCountDisplaySp",
    "searchAreaNameData",
    "searchCategoryNameData",
    "resultTags",
    "mapLinkArea",
    "mapLink",
    "currentCoordinate",
    "currentAddressNotFoundModal",
    "currentAddressErrorMessage",
    "searchFromCurrentPositionCheckPc",
    "searchFromCurrentPositionCheckSp",
    "searchSpFixed",
  ];

  connect() {
    this.#fetchPrefectures();
    this.selectPcCollapse = new Collapse(this.selectPcCollapseTarget, { toggle: false });
    this.filterSpModal = new Modal(this.filterSpModalTarget);
    this.currentAddressNotFoundModal = new Modal(this.currentAddressNotFoundModalTarget);
    this.selectPcCollapseTarget.addEventListener("hidden.bs.collapse", () => {
      // 地域選択が閉じられたらセット
      this.#setAreaText();
    });
    this.#setInitialValue();

    this.#closeCollapseOnExternalClick();
    document.addEventListener("turbo:frame-render", () => {
      this.#updateSearchCount();
      this.#updateSearchTag();
    });
    this.activeMapLink(); // トップの地域・カテゴリリンクから遷移した際に処理させる
    this.changeStatesResetButtonAndResultTags(); // トップの地域・カテゴリリンクから遷移した際に処理させる
    this.showDeleteKeywordButton();
    this.#loadButtonPositionAdjustment();
    if (this.hasSearchSpFixedTarget){
    this.#addScrollEventSp();
    }
  }

  // 各項目の初期値をセット
  #setInitialValue() {
    this.prefectureId = this.prefectureSelectPcTarget.value;
    this.prefectureName =
      this.prefectureSelectPcTarget.selectedIndex === 0
        ? ""
        : this.prefectureSelectPcTarget.options[this.prefectureSelectPcTarget.selectedIndex].textContent;
    this.cityId = this.citySelectPcTarget.value;
    this.cityName =
      this.citySelectPcTarget.selectedIndex === 0
        ? ""
        : this.citySelectPcTarget.options[this.citySelectPcTarget.selectedIndex].textContent;

    this.categoryId = this.categorySelectPcTarget.value;
    this.categoryName = this.categorySelectPcTarget.options[this.categorySelectPcTarget.selectedIndex].textContent;

    this.budgetMin = this.budgetMinSelectPcTarget.value;
    this.budgetMax = this.budgetMaxSelectPcTarget.value;
    this.budgetMinName = this.budgetMinSelectPcTarget.options[this.budgetMinSelectPcTarget.selectedIndex].textContent;
    this.budgetMaxName = this.budgetMaxSelectPcTarget.options[this.budgetMaxSelectPcTarget.selectedIndex].textContent;
    this.firstSelectBudget = null;
    this.budgetSelected = this.budgetMinSelectPcTarget.value && this.budgetMaxSelectPcTarget.value;

    this.rankRadioTargets.forEach(target => {
      if (target.checked) {
        this.rankId = target.value ? Number(target.value) : "";
        this.rankName = target.parentNode.textContent.trim();
      }
    });
    this.isWorking = this.workingCheckPcTarget.checked;
    this.isOwner = this.ownerCheckPcTarget.checked;

    if (this.hasSortSelectPcTarget) {
      this.sortValue = this.sortSelectPcTarget.value;
    }
  }

  // 予算選択時、矛盾が生じないように選択肢をdisableにする
  disableBudgetSelect(e) {
    const targetName = e.params.payload.target;
    const otherTargetName = targetName === "shopBudgetMin" ? targetName : "shopBudgetMax";
    const optionsPc = targetName === "shopBudgetMin" ? [...this.budgetMaxSelectPcTarget.options] : [...this.budgetMinSelectPcTarget.options];
    const optionsSp = targetName === "shopBudgetMin" ? [...this.budgetMaxSelectSpTarget.options] : [...this.budgetMinSelectSpTarget.options];
    const targetPc = targetName === "shopBudgetMin" ? this.budgetMinSelectPcTarget : this.budgetMaxSelectPcTarget;
    const targetSp = targetName === "shopBudgetMin" ? this.budgetMinSelectSpTarget : this.budgetMaxSelectSpTarget;
    const otherTargetPc = targetName === "shopBudgetMin" ? this.budgetMaxSelectPcTarget : this.budgetMinSelectPcTarget;
    const otherTargetSp = targetName === "shopBudgetMin" ? this.budgetMaxSelectSpTarget : this.budgetMinSelectSpTarget;
    const needsReset = targetName === "shopBudgetMin" ? Number(targetPc.value) > Number(otherTargetPc.value) : targetPc.value && otherTargetPc.value && Number(targetPc.value) < Number(otherTargetPc.value);

    if (!this.firstSelectBudget) {
      // 下限・上限どちらが最初に選択されたか保持
      this.firstSelectBudget = targetName;
    }
    if (needsReset) {
      // 選択済みの状態で矛盾する選択肢を選んだ場合、逆のselectをリセットする
      otherTargetPc.value = targetName === "shopBudgetMin" ? 99999999 : 0;
      otherTargetSp.value = targetName === "shopBudgetMin" ? 99999999 : 0;
      this.firstSelectBudget = otherTargetName;
      if (targetName === "shopBudgetMin") {
        this.#setBudgetMaxState(otherTargetPc.value, "上限なし");
      } else {
        this.#setBudgetMinState(otherTargetPc.value, "下限なし");
      }
    }

    // 選択に応じて下限・上限が矛盾する選択肢を無効化（最初に選択した側は無効化しない）
    optionsPc.forEach(option => {
      const needsDisable = targetName === "shopBudgetMin" ? targetPc.value && option.value && Number(targetPc.value) > Number(option.value) : targetPc.value && option.value && Number(targetPc.value) < Number(option.value);
      if (needsDisable && (!otherTargetPc.value || targetName === this.firstSelectBudget)) {
        option.disabled = true;
        return;
      };
      option.disabled = false;
    });
    // 選択に応じて下限・上限が矛盾する選択肢を無効化（最初に選択した側は無効化しない）
    optionsSp.forEach(option => {
      const needsDisable = targetName === "shopBudgetMin" ? targetSp.value && option.value && Number(targetSp.value) > Number(option.value) : targetSp.value && option.value && Number(targetSp.value) < Number(option.value);
      if (needsDisable && (!otherTargetSp.value || targetName === this.firstSelectBudget)) {
        option.disabled = true;
        return;
      };
      option.disabled = false;
    });
  }

  selectPrefecture(e) {
    const prefectureId = Number(e.currentTarget.value);
    const prefectureName =
      e.currentTarget.selectedIndex === 0 ? "" : e.currentTarget.options[e.currentTarget.selectedIndex].textContent;
    this.#setPrefectureState(prefectureId, prefectureName);
    this.#setCityState("", ""); // 市区町村のstateをリセット
    this.resetCurrentPositionCheck(); // 現在地のチェックをリセット
  }

  selectCity(e) {
    const cityId = Number(e.currentTarget.value);
    const selectedOption = e.currentTarget.options[e.currentTarget.selectedIndex];
    const cityName = selectedOption.value === "" ? "" : selectedOption.textContent;
    this.#setCityState(cityId, cityName);
    this.resetCurrentPositionCheck(); // 現在地のチェックをリセット
  }

  selectCategoryFrom(e) {
    const categoryId = Number(e.currentTarget.value);
    const categoryName = e.currentTarget.options[e.currentTarget.selectedIndex].textContent;
    this.#setCategoryState(categoryId, categoryName);
  }

  selectBudgetMinFrom(e) {
    const budgetMin = Number(e.currentTarget.value);
    const budgetMinName = e.currentTarget.options[e.currentTarget.selectedIndex].textContent;
    this.#setBudgetMinState(budgetMin, budgetMinName);
  }

  selectBudgetMaxFrom(e) {
    const budgetMax = Number(e.currentTarget.value);
    const budgetMaxName = e.currentTarget.options[e.currentTarget.selectedIndex].textContent;
    this.#setBudgetMaxState(budgetMax, budgetMaxName);
  }

  selectRadioRankFrom(e) {
    const rankId = e.currentTarget.value ? Number(e.currentTarget.value) : "";
    const rankName = e.currentTarget.parentNode.textContent.trim();
    this.rankRadioTargets.forEach(target => {
      if (target.value != rankId) {
        target.parentNode.classList.add("is_uncheck");
        return;
      }
      target.parentNode.classList.remove("is_uncheck");
    });
    this.#setRankState(rankId, rankName);
  }

  checkWorkingForm(e) {
    const isWorking = e.currentTarget.checked;
    this.#setWorkingState(isWorking);
  }

  checkOwnerForm(e) {
    const isOwner = e.currentTarget.checked;
    this.#setOwnerState(isOwner);
  }

  clickCategoryOnThumb(e) {
    // サムネイルのカテゴリがクリックされたらカテゴリフォームを書き換えて送信
    const categoryId = e.params.payload.categoryId;
    const categoryName = e.params.payload.categoryName;
    this.#setCategoryState(categoryId, categoryName);
    this.submitForm();
  }

  selectSort(e) {
    const sortValue = e.currentTarget.value;
    this.#setSortState(sortValue);
    this.submitForm();
  }

  /**
   * submitイベントだと検索フォームの入力も再描画されてしまうため、submitボタンのclickイベントで検索を実行するようにする
   */
  submitForm(e) {
    e?.preventDefault();
    this.#setAreaText();
    this.selectPcCollapse.hide();
    this.filterSpModal.hide();
    this.prefectureSelectPcTarget.disabled = false;
    this.prefectureSelectSpTarget.disabled = false;
    this.citySelectPcTarget.disabled = false;
    this.citySelectSpTarget.disabled = false;
    this.submitButtonTarget.click();
  }

  activeMapLink() {
    // 検索結果の地域をMAPで見るボタンをアクティブにする
    if (
      this.textFieldTarget.value !== "" ||
      this.prefectureId ||
      this.categoryId ||
      this.rankId ||
      this.isWorking ||
      this.isOwner
    ) {
      const isWorking = !this.isWorking ? "" : Number(this.isWorking);
      const isOwner = !this.isOwner ? "" : Number(this.isOwner);
      if (this.hasMapLinkTarget) {
        this.mapLinkTarget.setAttribute(
          "href",
          `/maps?is_search=true&is_first=true&keyword=${this.textFieldTarget.value}&category_id=${this.categorySelectPcTarget.value}&rank_id=${this.rankId}&is_working=${isWorking}&is_owner=${isOwner}&ken_id=${this.prefectureSelectPcTarget.value}&city_id=${this.citySelectPcTarget.value}&sort=${this.sortSelectPcTarget.value}`
        );
      }

      if (this.hasFilterSpButtonTarget) {
        this.filterSpButtonTarget.classList.add("is_active");
      }
      if (this.hasMapLinkAreaTarget) {
        this.mapLinkAreaTarget.classList.add("is_active");
      }
      return;
    }
    if (this.hasFilterSpButtonTarget) {
      this.filterSpButtonTarget.classList.remove("is_active");
    }
    if(this.hasMapLinkAreaTarget) {
      this.mapLinkAreaTarget.classList.remove("is_active");
    }
  }

  changeStatesResetButtonAndResultTags() {
    // 検索条件クリアボタンをアクティブにする
    // SPの検索画面で、検索条件なしの時の余白を調整
    if (
      this.textFieldTarget.value !== "" ||
      this.prefectureId ||
      this.categoryId ||
      this.budgetMin != 0 ||
      this.budgetMax != 99999999 ||
      this.rankId ||
      this.isWorking ||
      this.isOwner
    ) {
      if (this.hasResetAllConditionsWithSubmitButtonTarget) {
        this.resetAllConditionsWithSubmitButtonTarget.style.display = "flex";
      }
      if (this.hasResultTagsTarget) {
        this.resultTagsTarget.classList.remove("mb-0");
      }
      return;
    }
    if (this.hasResetAllConditionsWithSubmitButtonTarget) {
      this.resetAllConditionsWithSubmitButtonTarget.style.display = "none";
    }
    if (this.hasResultTagsTarget) {
      this.resultTagsTarget.classList.add("mb-0");
    }
  }

  // MAPで見るおよび検索絞り込み(SP)のボタンの位置をスクロール量に応じて調整する
  #loadButtonPositionAdjustment() {
    if (this.hasMapLinkAreaTarget) {
      // Mapボタンがカードと被らないように高さを取得
      const rect = this.mapLinkAreaTarget.getBoundingClientRect();
      const googleMapBtnVertical = rect.top; // Mapボタンの高さ（ボタン自体ではなく位置的な高さ）
      window.addEventListener("scroll", () => {
        const pageHeight = document.documentElement.scrollHeight;
        const sclTop = document.documentElement.scrollTop; // スクロールされた高さ
        const winHeight = window.innerHeight;
        const relPos = pageHeight - winHeight - sclTop; // スクロールできる残りの高さ

        const BASE_VALUE = 150;

        // 下までスクロールすると表示切替え
        if (relPos < BASE_VALUE) {
          this.mapLinkAreaTarget.classList.add("is_bottom");
          this.mapLinkTarget.classList.add("is_bottom");
          if (this.hasFilterSpButtonTarget) {
            this.filterSpButtonTarget.classList.add("is_bottom");
          }
        } else {
          this.mapLinkAreaTarget.classList.remove("is_bottom");
          this.mapLinkTarget.classList.remove("is_bottom");
          if (this.hasFilterSpButtonTarget) {
            this.filterSpButtonTarget.classList.remove("is_bottom");
          }
        }

        // Mapボタンがカードと被らないように調整
        if (googleMapBtnVertical > sclTop) {
          this.mapLinkAreaTarget.classList.remove("is_move");
        } else {
          this.mapLinkAreaTarget.classList.add("is_move");
        }
      });
    }
  }

  resetArea() {
    this.#resetAreaState();
    this.submitForm();
  }

  resetCategory() {
    this.#resetCategoryState();
    this.submitForm();
  }

  resetBudget() {
    this.#resetBudgetState();
    this.#resetBudgetSelect();
    this.submitForm();
  }

  resetRank() {
    this.#resetRankState();
    this.submitForm();
  }

  resetWorking() {
    this.#resetWorkingState();
    this.submitForm();
  }

  resetOwner() {
    this.#resetOwnerState();
    this.submitForm();
  }

  resetCurrentPositionCheck() {
    this.searchFromCurrentPositionCheckPcTarget.checked = false;
    this.searchFromCurrentPositionCheckSpTarget.checked = false;
  }

  resetAllConditions() {
    // すべての検索条件をリセット
    this.#resetAreaState();
    this.#resetCategoryState();
    this.#resetBudgetState();
    this.#resetBudgetSelect();
    this.#resetRankState();
    this.#resetWorkingState();
    this.#resetOwnerState();
    this.#setAreaText();
    this.resetCurrentPositionCheck();
  }

  resetAllConditionsWithSubmit() {
    // すべての検索条件をリセットして再送信
    this.#resetAreaState();
    this.#resetCategoryState();
    this.#resetBudgetState();
    this.#resetBudgetSelect();
    this.#resetRankState();
    this.#resetWorkingState();
    this.#resetOwnerState();
    this.resetCurrentPositionCheck();
    this.textFieldTarget.value = "";
    this.submitForm();
  }

  showDeleteKeywordButton() {
    if (!this.textFieldTarget.value) {
      this.searchAreaTarget.classList.remove("is_search");
      return;
    }
    this.searchAreaTarget.classList.add("is_search");
  }

  // キーワードを削除する
  deleteKeyword() {
    this.textFieldTarget.value = "";
    this.searchAreaTarget.classList.remove("is_search");
  }

  // PCの絞り込み条件設定エリアの表示切り替え
  toggleSearchOptionArea() {
    this.toggleSearchOptionAreaButtonPcTarget.classList.toggle("is_open");
    if (this.toggleSearchOptionAreaButtonPcTarget.classList.contains("is_open")) {
      this.searchOptionAreaTarget.style.height = this.searchOptionAreaTarget.scrollHeight + 'px';
      this.searchOptionAreaTarget.style.overflow = "visible";
      return;
    }
    this.searchOptionAreaTarget.style.overflow = "hidden";
    this.searchOptionAreaTarget.style.height = "0";
  }
  closeSearchOptionArea() {
    this.toggleSearchOptionAreaButtonPcTarget.classList.remove("is_open");
    this.searchOptionAreaTarget.style.overflow = "hidden";
    this.searchOptionAreaTarget.style.height = "0";
  }

  /**
   * checkboxにチェックが入った場合に、現在位置から地域を取得・セレクトボックスに設定する
   * @param {Event} e
   */
  async getAndSetCurrentAddress(e) {
    if (!e.target.checked) {
      this.prefectureSelectPcTarget.disabled = false;
      this.prefectureSelectSpTarget.disabled = false;
      this.citySelectPcTarget.disabled = false;
      this.citySelectSpTarget.disabled = false;
      return;
    }

    // WebViewで開いている場合、Flutterの処理を呼び出すためにJavascriptChannelにメッセージを送る
    if (typeof currentAddress !== "undefined") {
      // eslint-disable-next-line no-undef
      currentAddress.postMessage("");
    }

    // 現在地取得中に「適用する」ボタンを押させないようにする
    this.#toggleSubmitFilterButtonsWithCurrentPosition(true);
    // セレクトメニューのデフォルトテキストを取得中に切り替える
    this.#toggleAreaSelectDefaultTextWithCurrentPosition(true);

    this.prefectureSelectPcTarget.disabled = true;
    this.prefectureSelectSpTarget.disabled = true;
    this.citySelectPcTarget.disabled = true;
    this.citySelectSpTarget.disabled = true;

    const position =  await getCurrentPositionAsync().catch(() => {
      // WebViewで開いている場合、currentAddressオブジェクトが定義されているため、FlutterのJavascriptChannelにメッセージを送る
      if (typeof currentAddress !== "undefined") {
        // eslint-disable-next-line no-undef
        currentAddress.postMessage("");
      } else {
        alert("位置情報の利用が許可されていません。\n詳細は「位置情報の利用を許可」をご確認ください。");
        this.#toggleSubmitFilterButtonsWithCurrentPosition(false);
        this.#toggleAreaSelectDefaultTextWithCurrentPosition(false);
        this.resetCurrentPositionCheck();
      }
      return null;
    });
    if (position === null) return;

    const { latitude, longitude } = position.coords;
    this.#fetchAndSetAddressFromCoordinate({ latitude, longitude });
  }

  /**
   * Flutterで現在位置から取得した緯度経度を使用して、地域絞り込みのセレクトボックスを設定する
   */
  async getAndSetCurrentAddressFromWebview() {
    const [latitude, longitude] = JSON.parse(this.currentCoordinateTarget.value).map((e) => Number(e));
    this.#fetchAndSetAddressFromCoordinate({ latitude, longitude });
  }

  async #fetchAndSetAddressFromCoordinate({ latitude = 0, longitude = 0 }) {
    try {
      const result = await fetcher.get("/api/internal/v1/current_address", {
        params: { latitude: latitude, longitude: longitude }
      });
      const address = result.data;
      this.#setPrefectureState(address.kenId, address.kenName);
      this.#setCityState(address.cityId, address.cityName);
      this.citySelectPcTarget.disabled = true;
      this.citySelectSpTarget.disabled = true;
      this.#toggleSubmitFilterButtonsWithCurrentPosition(false);
      this.#toggleAreaSelectDefaultTextWithCurrentPosition(false);
      this.#setAreaText();
    } catch (e) {
      if (e.response && e.response.status === 400) {
        // 位置情報が国外の場合
        this.filterSpModal.hide();
        this.#toggleSubmitFilterButtonsWithCurrentPosition(false);
        this.resetCurrentPositionCheck();
        this.prefectureSelectPcTarget.disabled = false;
        this.prefectureSelectSpTarget.disabled = false;
        this.currentAddressErrorMessageTarget.innerText = "リベシティマップは現在日本国外の表示に対応していません"
        this.currentAddressNotFoundModal.show();
      }
      if (e.response && e.response.status === 404) {
        // 地域が見つからない場合
        this.filterSpModal.hide();
        this.#toggleSubmitFilterButtonsWithCurrentPosition(false);
        this.resetCurrentPositionCheck();
        this.prefectureSelectPcTarget.disabled = false;
        this.prefectureSelectSpTarget.disabled = false;
        this.currentAddressErrorMessageTarget.innerText = "この地域は現在地の取得に対応していません"
        this.currentAddressNotFoundModal.show();
      }
    }
  }

  #resetAreaState() {
    // 都道府県のセレクトボックスをリセット
    this.prefectureSelectPcTarget.selectedIndex = 0;
    this.prefectureSelectSpTarget.selectedIndex = 0;
    this.prefectureSelectPcTarget.disabled = false;
    this.prefectureSelectSpTarget.disabled = false;
    // 市区町村のセレクトボックスをリセット
    const defaultOption = document.createElement("option");
    defaultOption.textContent = "市区町村を選択";
    defaultOption.value = "";
    this.citySelectPcTarget.innerHTML = "";
    this.citySelectPcTarget.appendChild(defaultOption);
    this.citySelectPcTarget.disabled = true;
    const defaultOptionSp = defaultOption.cloneNode(true);
    this.citySelectSpTarget.innerHTML = "";
    this.citySelectSpTarget.appendChild(defaultOptionSp);
    this.citySelectSpTarget.disabled = true;

    // controllerで保持している検索条件の状態をリセット
    this.prefectureId = "";
    this.prefectureName = "";
    this.cityId = "";
    this.cityName = "";
  }

  #resetCategoryState() {
    // カテゴリのセレクトボックスをリセット
    this.categorySelectPcTarget.selectedIndex = 0;
    this.categorySelectSpTarget.selectedIndex = 0;

    // controllerで保持している検索条件の状態をリセット
    this.categoryId = "";
    this.categoryName = "";
  }

  #resetBudgetState() {
    // 予算のセレクトボックスをリセット
    this.budgetMinSelectPcTarget.value = 0;
    this.budgetMinSelectSpTarget.value = 0;
    this.budgetMaxSelectPcTarget.value = 99999999;
    this.budgetMaxSelectSpTarget.value = 99999999;

    // controllerで保持している検索条件の状態をリセット
    this.budgetMin = 0;
    this.budgetMax = 99999999;
    this.budgetMinName = "下限なし";
    this.budgetMaxName = "上限なし";
  }

  /**
   * 予算の無効化された選択肢および選択済み判定の状態をリセットする
   */
  #resetBudgetSelect() {
    this.firstSelectBudget = null;
    this.budgetSelected = false;
    [...this.budgetMinSelectPcTarget.options].forEach(option => {
      option.disabled = false;
    });
    [...this.budgetMaxSelectPcTarget.options].forEach(option => {
      option.disabled = false;
    });
    [...this.budgetMinSelectSpTarget.options].forEach(option => {
      option.disabled = false;
    });
    [...this.budgetMaxSelectSpTarget.options].forEach(option => {
      option.disabled = false;
    });
  }

  #resetRankState() {
    this.rankRadioTargets.forEach(target => {
      if (target.value !== "") {
        target.parentNode.classList.add("is_uncheck");
        return
      };
      target.parentNode.classList.remove("is_uncheck");
      target.checked = true;
    });
    // controllerで保持している検索条件の状態をリセット
    this.rankId = "";
    this.rankName = "すべて";
  }

  async #fetchPrefectures() {
    const result = await fetcher.get("/api/internal/v1/prefectures_with_cities");
    this.prefectures = result.data;
  }

  #resetWorkingState() {
    // 「会員が店頭にいる」のチェックボックスをリセット
    this.workingCheckPcTarget.checked = false;
    this.workingCheckSpTarget.checked = false;

    // controllerで保持している検索条件の状態をリセット
    this.isWorking = false;
  }

  #resetOwnerState() {
    // 「会員が経営している」のチェックボックスをリセット
    this.ownerCheckPcTarget.checked = false;
    this.ownerCheckSpTarget.checked = false;

    // controllerで保持している検索条件の状態をリセット
    this.isOwner = false;
  }

  // 地域検索・カテゴリ検索の入力欄はPC/SPで同期する必要があるため、コントローラのset~Stateメソッド内、
  // コントローラのプロパティを経由してPC/SPのフォーム入力値を同期する

  /**
   * 都道府県の選択状態をcontrollerで保持し、PC/SPの都道府県入力欄と同期する
   * @param {Number} prefectureId
   * @param {string} prefectureName
   */
  #setPrefectureState(prefectureId, prefectureName) {
    this.prefectureId = prefectureId;
    this.prefectureName = prefectureName;
    if (Number(this.prefectureSelectPcTarget.value) !== prefectureId)
      this.prefectureSelectPcTarget.value = prefectureId;
    if (Number(this.prefectureSelectSpTarget.value) !== prefectureId)
      this.prefectureSelectSpTarget.value = prefectureId;
    this.#setCityOptions(prefectureId);
  }

  /**
   * 市区町村の選択状態をcontrollerで保持し、PC/SPの市区町村入力欄と同期する
   * @param {Number} cityId
   * @param {string} cityName
   */
  #setCityState(cityId, cityName) {
    this.cityId = cityId;
    this.cityName = cityName;
    if (Number(this.citySelectPcTarget.value) !== cityId) this.citySelectPcTarget.value = cityId;
    if (Number(this.citySelectSpTarget.value) !== cityId) this.citySelectSpTarget.value = cityId;
  }

  /**
   * カテゴリの選択状態をcontrollerで保持し、PC/SPのカテゴリ入力欄と同期する
   * @param {Number} categoryId
   * @param {string} categoryName
   */
  #setCategoryState(categoryId, categoryName) {
    this.categoryId = categoryId;
    this.categoryName = categoryName;
    if (Number(this.categorySelectPcTarget.value) !== categoryId) this.categorySelectPcTarget.value = categoryId;
    if (Number(this.categorySelectSpTarget.value) !== categoryId) this.categorySelectSpTarget.value = categoryId;
  }

  /**
   * 予算（下限）の選択状態をcontrollerで保持し、PC/SPの予算入力欄と同期する
   * @param {Number} budgetMin
   * @param {string} budgetMinName
   */
  #setBudgetMinState(budgetMin, budgetMinName) {
    this.budgetMin = budgetMin;
    this.budgetMinName = budgetMinName;
    if (Number(this.budgetMinSelectPcTarget.value) !== budgetMin) this.budgetMinSelectPcTarget.value = budgetMin;
    if (Number(this.budgetMinSelectSpTarget.value) !== budgetMin) this.budgetMinSelectSpTarget.value = budgetMin;
  }

  /**
   * 予算（上限）の選択状態をcontrollerで保持し、PC/SPの予算入力欄と同期する
   * @param {Number} budgetMax
   * @param {string} budgetMaxName
   */
  #setBudgetMaxState(budgetMax, budgetMaxName) {
    this.budgetMax = budgetMax;
    this.budgetMaxName = budgetMaxName;
    if (Number(this.budgetMaxSelectPcTarget.value) !== budgetMax) this.budgetMaxSelectPcTarget.value = budgetMax;
    if (Number(this.budgetMaxSelectSpTarget.value) !== budgetMax) this.budgetMaxSelectSpTarget.value = budgetMax;
  }

  /**
   * ランクの選択状態をcontrollerで保持し、PC/SPの入力欄と同期する
   * @param {Number} categoryId
   */
  #setRankState(rankId, rankName) {
    this.rankId = rankId;
    this.rankName = rankName;

    this.rankRadioTargets.forEach(target => {
      if (target.value != rankId) return;
      target.checked = true;
    });
  }

  /**
   * 「会員が店頭にいる」のチェック状態をcontrollerで保持し、PC/SPの入力欄と同期する
   * @param {Boolean} isWorking
   */
  #setWorkingState(isWorking) {
    this.isWorking = isWorking;
    if (this.workingCheckPcTarget.checked !== isWorking) this.workingCheckPcTarget.checked = isWorking;
    if (this.workingCheckSpTarget.checked !== isWorking) this.workingCheckSpTarget.checked = isWorking;
  }

  /**
   * 「会員が経営している」のチェック状態をcontrollerで保持し、PC/SPの入力欄と同期する
   * @param {Boolean} isOwner
   */
  #setOwnerState(isOwner) {
    this.isOwner = isOwner;
    if (this.ownerCheckPcTarget.checked !== isOwner) this.ownerCheckPcTarget.checked = isOwner;
    if (this.ownerCheckSpTarget.checked !== isOwner) this.ownerCheckSpTarget.checked = isOwner;
  }

  /**
   * カテゴリの選択状態をcontrollerで保持し、PC/SPのカテゴリ入力欄と同期する
   * @param {string} sortValue
   */
    #setSortState(sortValue) {
      this.sortValue = sortValue;
      if (this.sortSelectPcTarget.value !== sortValue) this.sortSelectPcTarget.value = sortValue;
      if (this.sortSelectSpTarget.value !== sortValue) this.sortSelectSpTarget.value = sortValue;
    }

  // PC/SPの市区町村セレクトボックスにoptionタグを設定する
  /**
   *
   * @param {Number} prefectureId
   */
  #setCityOptions(prefectureId) {
    const cities = this.prefectures.find((e) => e.kenId === prefectureId)?.cities ?? [];
    const fragment = document.createDocumentFragment();

    const defaultOption = document.createElement("option");
    defaultOption.textContent = "市区町村を選択";
    defaultOption.value = "";
    fragment.appendChild(defaultOption);

    cities.forEach((city) => {
      const option = document.createElement("option");
      option.value = city.cityId;
      option.textContent = city.cityName;
      fragment.appendChild(option);
    });
    const fragmentSp = fragment.cloneNode(true);

    this.citySelectPcTarget.innerHTML = "";
    this.citySelectPcTarget.appendChild(fragment);
    this.citySelectPcTarget.disabled = false;
    this.citySelectSpTarget.innerHTML = "";
    this.citySelectSpTarget.appendChild(fragmentSp);
    this.citySelectSpTarget.disabled = false;
  }

  /**
   * 地域選択のCollapseの外側をクリックした時にCollapseを閉じる
   */
  #closeCollapseOnExternalClick() {
    document.addEventListener("click", (e) => {
      if (!e.target.closest("#selectPcModal")) this.selectPcCollapse.hide();
    });
  }

  /**
   * 検索実行時、合計件数表示を更新する（Turbo Frame, Turbo Streamで対応できないため）
   */
  #updateSearchCount() {
    const count = this.searchCountDataTarget.dataset.searchCount;
    this.searchCountDisplayPcTarget.textContent = `${count}件`;
    this.searchCountDisplaySpTarget.textContent = `${count}件`;
  }

  /**
   * 検索実行時、地域条件とカテゴリ条件のタグを更新する（Turbo Frame, Turbo Streamで対応できないため）
   */
  #updateSearchTag() {
    const resultTags = this.resultTagsTarget.children;
    [...resultTags].forEach(target => {
      if (target.tagName === "LI") {
        target.remove();
      }
    });
    const areaName = `${this.prefectureName}${this.cityName}`;
    const areaNameTag = !areaName
      ? ""
      : `<li>${areaName}<i class="fa-solid fa-xmark" data-action="click->search#resetArea click->search#resetCurrentPositionCheck"></i></li>`;
    const categoryNameTag = !this.categoryName || this.categoryName === "カテゴリーを選択"
      ? ""
      : `<li>${this.categoryName}<i class="fa-solid fa-xmark" data-action="click->search#resetCategory"></i></li>`;
    const budgetName = `${this.budgetMinName}〜${this.budgetMaxName}`;
    const budgetNameTag = this.budgetMinName === "下限なし" && this.budgetMaxName === "上限なし"
      ? ""
      : `<li>${budgetName}<i class="fa-solid fa-xmark" data-action="click->search#resetBudget"></i></li>`;
    const rankNameTag = !this.rankName || this.rankName === "すべて"
      ? ""
      : `<li>${this.rankName}<i class="fa-solid fa-xmark" data-action="click->search#resetRank"></i></li>`;
    const workingTag = !this.isWorking
      ? ""
      : `<li>会員が店頭にいる<i class="fa-solid fa-xmark" data-action="click->search#resetWorking"></i></li>`;
    const ownerTag = !this.isOwner
      ? ""
      : `<li>会員が経営している<i class="fa-solid fa-xmark" data-action="click->search#resetOwner"></i></li>`;

    const htmlStr = `${areaNameTag}${categoryNameTag}${budgetNameTag}${rankNameTag}${workingTag}${ownerTag}`;
    const tmpElement = document.createElement('div');
    tmpElement.innerHTML = htmlStr;
    this.resultTagsTarget.prepend(...tmpElement.childNodes);
  }

  #setAreaText() {
    const prefectureSelect = this.prefectureSelectPcTarget;
    const prefectureName = prefectureSelect.options[prefectureSelect.selectedIndex].textContent;
    const citySelect = this.citySelectPcTarget;
    const cityName = citySelect.value === "" ? "" : citySelect.options[citySelect.selectedIndex].textContent;
    const areaName = `${prefectureName}${cityName}`;
    this.areaSelectTarget.value = areaName === "都道府県を選択" ? "地域を選択" : areaName;
  }

  /**
   * 現在地取得中かどうかで「適用する」「絞り込み」ボタンの状態を切り替える
   * @param {boolean} isLoading
   */
  #toggleSubmitFilterButtonsWithCurrentPosition(isLoading) {
    if (isLoading) {
      this.submitFilterSpButtonTarget.disabled = true;
      this.submitFilterSpButtonTarget.textContent = "現在地取得中...";
    } else {
      this.submitFilterSpButtonTarget.disabled = false;
      this.submitFilterSpButtonTarget.innerHTML = '結果を表示';
    }
  }

    /**
   * 現在地取得中かどうかで地域選択セレクトメニューのデフォルトテキストを切り替える
   * @param {boolean} isLoading
   */
    #toggleAreaSelectDefaultTextWithCurrentPosition(isLoading) {
      if (isLoading) {
        this.areaSelectTarget.value = "取得中..."
        this.prefectureSelectPcTarget.value = "";
        this.citySelectPcTarget.value = "";
        this.prefectureSelectPcTarget[0].innerText = "取得中..."
        this.citySelectPcTarget[0].innerText = "取得中..."
      } else {
        this.prefectureSelectPcTarget[0].innerText = "都道府県を選択";
        this.citySelectPcTarget[0].innerText = "市区町村を選択";
      }
    }

  // スクロールイベントSP
  #addScrollEventSp() {
    let startPos = 0;
    const searchFormWrap = this.searchSpFixedTarget

    window.addEventListener("scroll", function() {
      // スクロール量の取得 iPhoneスクロールバインド対策
      const winScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (winScrollTop > startPos && winScrollTop > 100) {
        //下にスクロールしたとき
        searchFormWrap.classList.add("is_hide");
      } else {
        //上にスクロールしたとき
        searchFormWrap.classList.remove("is_hide");
      }

      // 現在位置を更新
      startPos = winScrollTop;
    });
  }
}
