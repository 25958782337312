import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader"

// Connects to data-controller="single-map"
export default class extends Controller {
  static targets = ["mapWrapper"];

  connect() {
    this.shop = gon.shop;
    this.shopCategoryNameEn = gon.category_name_en;
    
    const loader = new Loader({
      apiKey: gon.google_map_api_key_for_frontend,
      version: "weekly",
    });
    loader.load().then(() => {
      this.#initMap();
    });
  }

  #initMap(){
    // 地図の位置情報(緯度・経度)を取得
    const center = {lat: Number(this.shop.latitude), lng: Number(this.shop.longitude) };
    const map = new google.maps.Map(this.mapWrapperTarget, {
      zoom: 15, // ズームレベルの指定
      center: center,
      clickableIcons: false, // 地図上の他のアイコンクリック設定
      fullscreenControl: false, // 地図全画面表示ボタンを非表示
      mapTypeControl: false, // 地図・航空写真切り替えのボタンを非表示
      styles: [{
        featureType: 'poi',
        stylers: [
          { visibility: 'off' }, // デフォルトの地図上の他施設ピンを非表示にする
        ]
      }]
    });
    const transitLayer = new google.maps.TransitLayer();
    transitLayer.setMap(map);
  
    // マーカー作成
    new google.maps.Marker({
      position: map.getCenter(),
      map,
      icon: {
        url: this.shop.pin_icon_url,
        scaledSize: new google.maps.Size(72, 86),
      },
      labelContent: this.shop.name,
      labelClass: `markerLabel is_${this.shopCategoryNameEn} shop_${this.shop.id}`,
      labelAnchor: new google.maps.Point(-50, 0)
    });
  }
}
