import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-more-description
export default class extends Controller {
  static targets = ["toggleButton", "toggleElement"];

  initialize() {
    this.element[this.identifier] = this; // 別コントローラーからメソッドを呼び出せるようにする
  }

  connect() {
    // 読み込み時詳細ページおよび口コミ一覧のみで動作するように（マップページ詳細窓でも使用しているが読み込み時は正しく動作しないため）
    if (location.pathname.startsWith("/shops/") || location.pathname === "/reviews") {
      this.shopToggleButtonIfUsed();
    }
  }

  // クリックされた「続きを読む」を開く
  // ページ内に複数続きを読むがある場合に対応できるよう、targetを使用していない
  toggleMoreDescription(e) {
    const moreButton = e.currentTarget;
    // 店舗説明・口コミ内容の続きを読むどちらにも対応できるよう分岐
    const description = e.currentTarget.previousElementSibling?.closest('[data-toggle-more-description-target="toggleElement"]') || e.currentTarget.previousElementSibling?.querySelector('[data-toggle-more-description-target="toggleElement"]');
    if (description.classList.contains("is_open")) {
      description.classList.remove("is_open");
      moreButton.innerHTML = `続きを読む<i class="fa-solid fa-chevron-down"></i>`;
      return;
    }
    description.classList.add("is_open");
    moreButton.innerHTML = `閉じる<i class="fa-solid fa-chevron-up"></i>`;
  }

  // 全ての「続きを読む」を閉じる
  closeAllMoreDescription() {
    const count = this.toggleElementTargets.length;
    for (let i = 0; i < count; i++) {
      this.toggleElementTargets[i].classList.remove("is_open");
      this.toggleButtonTargets[i].innerHTML = `続きを読む<i class="fa-solid fa-chevron-down"></i>`;
    }
  }

  // 店舗説明の内容があれば続きを読むが表示されるようにする
  shopToggleButtonIfUsed() {
    const count = this.toggleElementTargets.length;
    const reviewBaseHeight = 60;
    for (let i = 0; i < count; i++) {
      const clampedHeight = this.toggleElementTargets[i].offsetHeight;
      const fullHeight = this.toggleElementTargets[i].scrollHeight;
      const isReview = this.toggleElementTargets[i].parentNode.classList.contains("js_reviewText");
      if(!isReview && clampedHeight === fullHeight) {
        this.toggleButtonTargets[i].style.display = "none";
        continue;
      }
      if(isReview && reviewBaseHeight > fullHeight) {
        this.toggleButtonTargets[i].style.display = "none";
        continue;
      }
      this.toggleButtonTargets[i].style.display = "inline-block"; 
    }
  }
}
