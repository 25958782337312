import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["urlInputTemplate", "urlList", "input"];

  // 振る舞い単位でコントローラーを分割する
  // 難しかったら、ページ単位でコントローラー分割ですかね
  connect() {
  }

  submit() {
    this.element.requestSubmit();
  }
}
