import { Controller } from "@hotwired/stimulus"
import { getCurrentPositionAsync } from "../src/utils/current_position"

// Connects to data-controller="top"
export default class extends Controller {
  static targets = [
    "currentCoordinate",
    "currentCoordinateButton",
    "mapButton",
    "mapButtonArea",
    "searchSpFixed",
  ];

  connect() {
    this.#addScrollEvent();
    this.#addScrollEventSp();
  }

  // 現在地から取得した緯度経度を使用してMapページに遷移する
  async getPositionAndSubmit(e) {
    // 送信をキャンセル
    e.preventDefault();

    // 全画面ローディング（full-screen-loadingコントローラーのものを使う）
    this.element["full-screen-loading"].submit();

    // 現在値情報を取得
    const position =  await getCurrentPositionAsync().catch(() => {
      // WebViewで開いている場合、currentAddressオブジェクトが定義されているため、FlutterのJavascriptChannelにメッセージを送る
      if (typeof currentAddress !== "undefined") {
        // eslint-disable-next-line no-undef
        currentAddress.postMessage(`["${this.currentCoordinateTarget.id}","${this.currentCoordinateButtonTarget.id}"]`);
      } else {
        alert("位置情報の利用が許可されていません。\n詳細は「位置情報の利用を許可」をご確認ください。");
        this.element["full-screen-loading"].hidden();
      }
      return null;
    });

    if (position === null) return;

    // Mapページへ遷移する
    const href = `${e.target.href}?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&from_tops=true`;
    document.location.assign(href);
  }

  // 現在地から取得した緯度経度を使用してMapページに遷移する（アプリ版）
  getPositionAndSubmitFromWebview(e) {
    // Flutterでセットした緯度・経度
    const [latitude, longitude] = JSON.parse(this.currentCoordinateTarget.value).map((e) => Number(e));

    // Mapページへ遷移する
    const href = `${e.params.mapUrl}?latitude=${latitude}&longitude=${longitude}&from_tops=true`;
    document.location.assign(href);
  }

  // スクロールイベント
  #addScrollEvent() {
    const rect = this.mapButtonTarget.getBoundingClientRect();
    const googleMapBtnHeight = rect.top - 80; // Mapボタンの高さ（ボタン自体ではなく位置的な高さ）

    window.addEventListener("scroll", () => {
      const pageHeight = document.documentElement.scrollHeight;
      const sclTop = document.documentElement.scrollTop;
      const winHeight = window.innerHeight;
      const relPos = pageHeight - winHeight - sclTop;

      const BASE_VALUE = 150;

      // 下までスクロールすると表示切替え
      if (relPos < BASE_VALUE) {
        this.mapButtonTarget.classList.add("is_bottom");
        this.mapButtonAreaTarget.classList.add("is_bottom");
      } else {
        this.mapButtonTarget.classList.remove("is_bottom");
        this.mapButtonAreaTarget.classList.remove("is_bottom");
      }

      // Mapボタンがカードと被らないように調整
      if (googleMapBtnHeight > sclTop) {
        this.mapButtonAreaTarget.classList.remove("is_move");
      } else {
        this.mapButtonAreaTarget.classList.add("is_move");
      }
    });
  }

  // スクロールイベントSP
  #addScrollEventSp() {
    let startPos = 0;
    const searchFormWrap = this.searchSpFixedTarget

    window.addEventListener("scroll", function() {
      // スクロール量の取得 iPhoneスクロールバインド対策
      const winScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (winScrollTop > startPos && winScrollTop > 100) {
        //下にスクロールしたとき
        searchFormWrap.classList.add("is_hide");
      } else {
        //上にスクロールしたとき
        searchFormWrap.classList.remove("is_hide");
      }

      // 現在位置を更新
      startPos = winScrollTop;
    });
  }
}
