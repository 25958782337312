import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="change-tabs"
export default class extends Controller {
  static targets = [
    "tab",
    "detailTab",
    "reviewTab",
    "detailContent",
    "reviewContent"
  ];

  connect() {
    // タブの切替要素が完全に表示されたら処理させる
    // Intersection Observerの設定
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 要素が完全に表示された場合の処理
          const self = this;
          setTimeout(function() {
            // 店舗説明の内容があれば続きを読むが表示されるようにする（toggle-more-descriptionコントローラーのものを使う）
            self.element["toggle-more-description"].shopToggleButtonIfUsed();
          }, 50);
        }
      });
    });
    const elementsToObserve = [
      this.detailContentTarget,
      this.reviewContentTarget
    ];
    // 要素を監視する
    elementsToObserve.forEach(element => observer.observe(element));

    // 口コミ投稿・更新の成功メッセージがあればタブを口コミに切り替える
    const flashSuccess = document.querySelector('.alert-success');
    const isIncludesReviewMessage = flashSuccess && (flashSuccess.textContent.includes("口コミを投稿しました") || flashSuccess.textContent.includes("口コミの内容を更新しました"))
    if (location.pathname.startsWith("/shops/") && isIncludesReviewMessage) {
      this.changeReviewTab();
    }
  }

  // クリックされたタブにのみis_currentをつけ、それ以外のis_currentを削除
  // タブ内容の切り替えはbootstrapで実行されている
  changeTab(e) {
    this.tabTargets.forEach(target => {
      if (target !== e.currentTarget) {
        target.classList.remove("is_current")
      }
    });
    e.currentTarget.classList.add("is_current");
    
    // MAPページのみ：口コミタブ表示している際は店舗画像を非表示に
    const path = window.location.pathname;
    if (path.startsWith("/maps")) {
      const detailWindowHeader = document.getElementById("detailWindowHeader"); // targetに設定するとMAPページ以外でエラーが出るのでIDで判別
      if (this.reviewTabTarget.classList.contains("is_current")) {
        detailWindowHeader.classList.add("is_hide");
        return;
      }
      detailWindowHeader.classList.remove("is_hide");
    }
  }
  // 口コミタブをクリックさせる
  changeReviewTab() {
    if (this.reviewTabTarget.classList.contains("is_current")) return;
    this.reviewTabTarget.click();
  }
}
