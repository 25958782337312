import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import * as geofire from 'geofire-common';
import { fetcher } from "../src/utils/fetcher";
import { getCurrentPositionAsync } from "../src/utils/current_position"
import { settingMap } from "../src/setting_map";
import noImgUrl from '../images/no_image.png'; // viteのURLに自動的に切り替わる
import samePositionShopsImgUrl from '../images/shops.png';
import clusterImgUrl from '../images/cluster.png';

const DEFAULT_ZOOM_LEVEL = 15;
const GET_SURROUNDING_SHOPS_LIMIT = 60; // 一度の再取得上限
const MARKER_ICON_SIZE_WIDTH = 72;
const MARKER_ICON_SIZE_HEIGHT = 86;
const CENTER_LATITUDE_OFFSET = 0.004491; // 約500m分の緯度
const ACTIVE_MARKER_ZINDEX = 10000000; // 拡大表示にする際のz-index（この値にすることで常にクラスタよりも手前に表示される）

// Connects to data-controller="multiple-map"
export default class extends Controller {
  static targets = [
    "mapWrapper",

    /* 詳細窓関連 */
    "detailWindow",
    "detailArrow",
    "detailContainer",
    "detailWindowLoading",
    "detailWindowError",
    "detailHeaderImg",
    "detailHeaderCategory",
    "title",
    "address",
    "bookmarkButton",
    "bookmarkCount",
    "bookmarkTooltip",
    "detailWindowTitle",
    "detailWindowInfolist",
    "detailWindowWorkingHoursSection",
    "businessHours",
    "holiday",
    "workingHours",
    "budget",
    "description",
    "shopUrlArea",
    "shopUrlRow",
    "userName",
    "userIcon",
    "userProfileLink",
    "displayRank",
    "detailLinkPc",
    "detailLinkSp",
    "mapLinkSp",
    "detailTabs",
    "reviewTab",
    "detailTab",
    "reviewList",
    "reviewListItemTempLate",
    "reviewListItem",
    "reviewImageTemplate",
    "slideImages",

    /* アラート表示・各種ボタン */
    "limitAlert",
    "zoomAlert",
    "mapCloseButton",
    "openSearchWindowButtonPc",

    /* 検索関連 */
    "searchWindow",
    "searchArrow",
    "searchContainer",
    "searchForm",
    "searchTextFieldAreaPc",
    "searchTextFieldAreaSp",
    "searchTextFieldPc",
    "searchTextFieldSp",
    "searchCategoryPc",
    "searchCategorySp",
    "searchRankPc",
    "searchRankSp",
    "searchWorkingPc",
    "searchWorkingSp",
    "searchOwnerPc",
    "searchOwnerSp",
    "searchSubmitButton",
    "searchKenId",
    "searchCityId",
    "searchLatitude",
    "searchLongitude",
    "searchZoomLevel",
    "searchRadius",
    "searchSort",
    "searchNewAreaButton", // このエリアで検索ボタン
    "currentCoordinate",
    "resetAllConditionsButtonPc",
    "resetAllConditionsButtonSp",
    "isOpenSearchWindow",

    /* 同一住所関連 */
    "sameAddressWindow",
    "sameAddressWindowCloseArrow",
    "sameAddressWindowLoading",
    "sameAddressWindowError",
    "sameAddressShopCount",
    "sameAddressShopListContainer",
    "sameAddressShopList",
    "sameAddressShopListItemTempLate",
    "sameAddressShopListItem",
    "sameAddressShopImage",
    "sameAddressShopName",
    "sameAddressShopAddress",
    "sameAddressShopUserIcon",
    "sameAddressShopUserRankIcon",
    "sameAddressShopUserName",
    "sameAddressShopCategoryWrap",
    "sameAddressShopCategory",
    "sameAddressDetailContainer",
    "sameAddressDetailWindowLoading",
    "sameAddressDetailWindowError",
    "sameAddressDetailWindowHeader",
    "sameAddressDetailHeaderImg",
    "sameAddressDetailHeaderCategory",
    "sameAddressDetailWindowTitle",
    "sameAddressDetailWindowInfolist",
    "sameAddressTitle",
    "sameAddressAddress",
    "sameAddressBusinessHours",
    "sameAddressHoliday",
    "sameAddressBudget",
    "sameAddressWorkingHoursSection",
    "sameAddressWorkingHours",
    "sameAddressDescription",
    "sameAddressShopUrlRow",
    "sameAddressShopUrlArea",
    "sameAddressUserIcon",
    "sameAddressUserName",
    "sameAddressUserProfileLink",
    "sameAddressDisplayRank",
    "sameAddressDetailLink",
  ];

  connect() {
    this.#setInitialState();

    const loader = new Loader({
      apiKey: gon.google_map_api_key_for_frontend,
      version: "weekly",
    });
    loader.load().then(() => {
      this.#initMap();
      this.#initMarkers();

      this.#setSearchCenterPosition();
      this.#setSearchZoomLevel();
      this.#setSearchRadius();
    });

    this.showDeleteKeywordButton();

    // 検索時は検索窓を開く
    const isOpenSearchWindow = this.#getParam("is_open_search_window");
    if (this.#getParam("is_search") && (isOpenSearchWindow === "" || isOpenSearchWindow)) {
      this.openSearchWindow(true);
    }
  }

  // 各初期値を設定
  #setInitialState() {
    /**
     * マップオブジェクト
     * @type {?Object}
     */
    this.map = null;

    /**
     * railsから読み込んだ店舗データ配列
     * @type {Array}
     */
    this.nearShops = []

    /**
     * 開いているピン配列
     * @type {Array}
     */
    this.openedMarkers = [];

    /**
     * 非表示中のクラスター配列
     * 一定以上縮小で全てのピン・クラスタを非表示にする際に使用
     * @type {Array}
     */
    this.hiddenClusters = [];

    /**
     * 非表示中の複数用ピン
     * 複数用ピンを単体ピン表示にした際に一時的に非表示にするのに使用
     * @type {?Object}
     */
    this.hiddenSamePositionMarker = null;

    /**
     * 拡大表示中のピン
     * 拡大表示中のピンがない場合はnull
     * @type {?Object}
     */
    this.activeMarker = null;

    /**
     * 拡大後、元の大きさに戻したピンのz-index
     * @type {number}
     */
    this.activeMarkerAfterZIndex = 1;

    /**
     * ブラウザ読み込み時の中心位置
     * ドラッグした地図上の距離の比較で使用
     * @type {{latitude: number|null, longitude: number|null}}
     */
    this.originCenterPosition = { latitude: null, longitude: null };

    // ブラウザ読み込み時判定
    this.loadedByBrowser = true;
    // PanToによる地図の移動判定
    this.isPanTo = false;
    // 制限アラート表示済み判定
    this.showedLimitAlert = false;
    // このエリアを検索ボタン表示済み判定
    this.showedSearchNewAreaButton = false;
    // 詳細窓のスクロール位置保持用
    this.detailWindowScroll = 0;
    // 検索窓のスクロール位置保持用
    this.searchWindowScroll = 0;
    // モバイル判定
    this.isMobile = window.matchMedia("(max-width: 768px)").matches;
  }

  // マップの初期設定
  #initMap() {
    // 地図の位置情報(緯度・経度)を取得
    const centerPoint = gon.center_point;
    let centerLatitude;
    if (this.isMobile) {
      // スマホの場合はピンと同じ位置だと詳細窓に隠れるので上にずらす
      centerLatitude = Number(centerPoint.latitude) - CENTER_LATITUDE_OFFSET;
    } else {
      centerLatitude = Number(centerPoint.latitude);
    }
    const centerLongitude = Number(centerPoint.longitude);
    const center = {lat: centerLatitude, lng: centerLongitude };
    // ドラッグ時の比較用に最終取得の位置を格納
    this.originCenterPosition = {
      latitude: centerLatitude,
      longitude: centerLongitude
    }
    const zoomLevel = this.#getParam("zoom_level");

    this.map = settingMap(this.mapWrapperTarget, zoomLevel, DEFAULT_ZOOM_LEVEL, center);
  }

  // ピンの初期表示
  #initMarkers() {
    this.nearShops = gon.near_shops;

    // 同じ位置のデータをまとめる
    const nearShopsObj = this.#getUnitedSamePositionShops(this.nearShops);

    // 周辺店舗のピンを作成して開く
    for (const key of Object.keys(nearShopsObj)) {
      const samePositionShops = nearShopsObj[key];
      let shopIds
      let labelName;
      let shopImageUrl;
      let categoryNameEn;
      let position;
      let pinIconUrl;
      if (samePositionShops.length === 1) {
        // 同位置の店舗が1件のみの場合
        const nearShop = samePositionShops[0];
        const nearShopData = nearShop.shop_data;
        shopIds = [nearShopData.id];
        labelName = nearShopData.name;
        shopImageUrl = nearShop.shop_image_url;
        categoryNameEn = nearShop.category_name_en;
        position = this.#getPosition(nearShopData.latitude, nearShopData.longitude);
        pinIconUrl = nearShopData.pin_icon_url;
      } else {
        // 同位置に複数店舗ある場合
        const countShops = samePositionShops.length;
        shopIds = samePositionShops.map(shop => shop.shop_data.id);
        labelName = `${countShops}件あり`;
        shopImageUrl = null;
        categoryNameEn = "same";
        position = this.#getPosition(samePositionShops[0].shop_data.latitude, samePositionShops[0].shop_data.longitude);
        pinIconUrl = samePositionShopsImgUrl;
      }
      const marker = this.#getMarker(position, shopIds, pinIconUrl, labelName, categoryNameEn);
      this.activeMarkerAfterZIndex = 1;
      this.openedMarkers.push(marker);

      // ピンをクリックしたら検索窓と同住所の店舗一覧窓を開けるよう監視
      this.#loadMarkerListener(marker, pinIconUrl, shopIds, categoryNameEn, shopImageUrl);
    };

    // 上限件数以上対象がある場合、アラートを表示
    if (this.nearShops.length >= GET_SURROUNDING_SHOPS_LIMIT) {
      this.showedLimitAlert = true;
      this.#openLimitAlert();
    }

    // クラスタ化
    this.#generateCluster();

    // 中心店舗の初期表示処理
    this.#initCenterShop();

    // 地図のクリック・ドラッグ・拡大縮小を監視
    this.#mapClickListener();
    this.#loadDragAndZoomListener();
  }

  // 詳細ページ経由の中心店舗の初期表示
  #initCenterShop() {
    // 詳細ページ経由の場合の店舗ID（一度でも検索したら中心店舗IDはnullで扱う）
    const isSearch = this.#getParam("is_search");
    const centerShopId = isSearch ? null : this.#getParam("shop_id");
    if (!centerShopId) return;

    // 中心店舗がある場合（店舗詳細画面経由の初期表示の場合）
    const centerSamePositionMarker = this.#getSamePositionMarkerByShopId(Number(centerShopId));
    if (centerSamePositionMarker) {
      // 複数ピンに中心店舗が含まれる場合は複数ピンをバラす
      this.#individualizationSamePositionMarker(centerSamePositionMarker, false);
    }
    // 中心店舗のピンを取得
    const currentMarker = this.#getOpenedMarkerByShopId(Number(centerShopId));
    // 中心店舗のピンをクラスタから削除
    this.#removeFromCluster(currentMarker);

    // ピンを拡大表示にし、詳細窓を開く
    // setMapされる前に処理が走ってエラーになることがあるので対策
    const self = this;
    setTimeout(function() {
      self.#setActiveMarker(currentMarker, currentMarker.icon.url);
      const centerShop = self.#getShop(Number(centerShopId));
      self.#openDetailWindow(centerShop.shop_data.id, centerShop.category_name_en, centerShop.shop_image_url, currentMarker);
    }, 150);
  }

  /**
   * 店舗データを位置ごとの配列にまとめて返す
   * @param {Array} shops - 店舗データの配列
   * @return {Object} - 位置ごとにまとめられた店舗データのオブジェクト
   */
  #getUnitedSamePositionShops(shops) {
    const shopsObj = {};
    shops.forEach((shop, i) => {
      const positionStr = `${shop.shop_data.latitude}_${shop.shop_data.longitude}`;
      if (!shopsObj[positionStr]) {
        shopsObj[positionStr] = [shop];
      } else {
        shopsObj[positionStr].push(shop);
      }
    });
    return shopsObj;
  }

  /**
   * 緯度と経度から位置情報を作成する
   * @param {number} latitude - 緯度
   * @param {number} longitude - 経度
   * @return {google.maps.LatLng} - 位置情報のオブジェクト
   */
  #getPosition(latitude, longitude) {
    return new google.maps.LatLng({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    });
  }

  // サイド（スマホは下部）の窓を考慮した上での表示領域の中心にピンが来るよう移動
  #panToDisplayCenterArea(marker, isDetailWindow = false) {
    // ピンが表示領域の中心になるように地図を移動する
    const markerPosition = marker.getPosition();
    const projection = this.map.getProjection();
    const pixelCenter = projection.fromLatLngToPoint(markerPosition);

    const browserWidth = window.innerWidth;
    let xOffset = 0;
    let yOffset = 0;
    if (browserWidth > 768) {
      // PC幅：詳細窓のみ or 検索窓+同住所窓 で位置を変える
      xOffset = isDetailWindow ?
        this.detailWindowTarget.offsetWidth / 2 :
        (this.detailWindowTarget.offsetWidth / 2) + (this.sameAddressWindowTarget.offsetWidth / 2);
    } else {
      // SP幅：詳細窓 or 同住所窓 で位置を変える
      const browserHeight = window.innerHeight;
      const rect = isDetailWindow ?
        this.detailWindowTarget.getBoundingClientRect() :
        this.sameAddressWindowTarget.getBoundingClientRect();
      yOffset = (browserHeight - rect.top) / 2;
    }

    // 移動するピクセル数をズーム倍率で考慮する
    const zoom = this.map.getZoom();
    const scale = Math.pow(2, zoom);
    const newPixelCenter = new google.maps.Point(pixelCenter.x - (xOffset / scale), pixelCenter.y + (yOffset / scale));
    const newCenter = projection.fromPointToLatLng(newPixelCenter);

    this.isPanTo = true;
    this.map.panTo(newCenter);
  }

  // ピンを監視し、クリックしたら詳細窓を開く
  #loadMarkerListener(marker, pinIconUrl, shopIds, categoryNameEn, shopImageUrl = null) {
    const self = this;
    marker.addListener("click", async () => {
      self.sameAddressDetailWindowErrorTarget.style.display = "none";

      if (self.hiddenSamePositionMarker && self.#isIncludeHiddenPositionMarker(self.activeMarker)) {
        // 非表示にしていた複数ピンを再表示
        self.#uniteSamePositionMarker();
      }

      const activeMarkerIconURL = self.#getIconUrlFromActiveMarker();
      if (self.activeMarker && activeMarkerIconURL) {
        // アクティブなピンが複数ピンの中身以外であればクラスタ内に戻す
        if (!self.#isIncludeHiddenPositionMarker(self.activeMarker)) {
          self.markerClusterer.addMarker(self.activeMarker);
        }
        // アクティブなピンがあれば元の状態に戻す
        marker.setZIndex(self.activeMarkerAfterZIndex);
        self.#setMarkerIcon(self.activeMarker, activeMarkerIconURL);
      }
      if (shopIds.length === 1 ) {
        // 通常のピンならクラスタから削除（複数用ピンはクラスタから削除しない）
        self.#removeFromCluster(marker);
      }
      // クリックされたピンを拡大表示にする
      self.#setActiveMarker(marker, pinIconUrl);

      if (categoryNameEn !== "same") {
        // 通常のピンの場合：詳細窓を開く
        self.#openDetailWindow(shopIds[0], categoryNameEn, shopImageUrl, marker);
        return;
      }
      // 複数表示用のピンの場合：同住所一覧窓を開く
      self.openSameAddressWindow(shopIds, marker);
    });
  }

  // ピン単体のクリックイベントリスナーを解除し、マップから削除する
  #removeMarker(marker) {
    google.maps.event.clearListeners(marker, 'click');
    marker.setMap(null);
    this.openedMarkers = this.openedMarkers.filter(openedMarker => {
      return openedMarker.label.className !== marker.label.className;
    });
  }

  /**
   * 指定された店舗IDに該当する店舗データを初期データ配列から取得する
   * @param {string} shopId - 取得する店舗のID
   * @returns {Object} - 指定された店舗IDに該当する店舗データ
   */
  #getShop(shopId) {
    return this.nearShops.find(shop => shop.shop_data.id === shopId);
  }

  // 指定のピンをクラスタから削除し、クラスタから独立した表示にする（拡大縮小してもクラスタ化されない状態になる）
  #removeFromCluster(marker) {
    // クラスタからピンを削除
    this.markerClusterer.removeMarker(marker);
    if (!marker.map) {
      // 該当ピンがクラスタ表示になっている場合は個別表示にする
      marker.setMap(this.map);
    }
  }

  // 複数ピンを個別表示にする
  #individualizationSamePositionMarker(samePositionMarker) {
    // 複数ピンを非表示にする
    this.hiddenSamePositionMarker = samePositionMarker;
    this.hiddenSamePositionMarker.setMap(null);
    // クラスタからも複数ピンを一旦削除
    if (this.markerClusterer) {
      this.markerClusterer.removeMarker(this.hiddenSamePositionMarker);
    }

    // 個別のピンを挿す
    const shopIds = this.#getShopIdsFromMarker(samePositionMarker);
    shopIds.forEach(shopId => {
      const shop = this.#getShop(shopId);
      const shopData = shop.shop_data;
      const position = this.#getPosition(shopData.latitude, shopData.longitude);
      const marker = this.#getMarker(position, [shopData.id], shopData.pin_icon_url, shopData.name, shop.category_name_en);
      this.openedMarkers.push(marker);
    });
  }

  // 個別表示から複数ピン表示にする
  #uniteSamePositionMarker(isActive = false) {
    if (!this.hiddenSamePositionMarker) return;

    // 非表示にしていた複数ピンを再表示
    const shopIds = this.#getShopIdsFromMarker(this.hiddenSamePositionMarker);
    shopIds.forEach(shopId => {
      const removeMaker = this.#getOpenedMarkerByShopId(shopId);
      if (removeMaker) {
        // 個別のピンを削除
        this.#removeMarker(removeMaker);
      }
    });
    // 再表示し、クラスタに複数ピンを追加
    this.hiddenSamePositionMarker.setMap(this.map);
    this.markerClusterer.addMarker(this.hiddenSamePositionMarker);

    if (isActive) {
      // 複数ピンを拡大表示にする場合
      // setMapされる前に処理が走ってエラーになることがあるので対策
      const self = this;
      setTimeout(function() {
        self.#setActiveMarker(self.hiddenSamePositionMarker, samePositionShopsImgUrl);
        self.hiddenSamePositionMarker = null;
      }, 150);
      return;
    }
    this.hiddenSamePositionMarker = null;
    this.activeMarker = null;
  }

  // ピン一覧から指定shopIdのピンを取得する（複数用ピンは取得しない）
  #getOpenedMarkerByShopId(shopId) {
    const marker = this.openedMarkers.filter(marker => {
      const shopIds = this.#getShopIdsFromMarker(marker);
      if (shopIds.length >= 2) return false;

      return shopIds.includes(shopId);
    })[0];
    return marker || null;
  }

  // ピン一覧から指定shopIdが含まれる複数用ピンを取得する
  #getSamePositionMarkerByShopId(shopId) {
    const marker = this.openedMarkers.filter(marker => {
      const shopIds = this.#getShopIdsFromMarker(marker);
      if (shopIds.length >= 2 && shopIds.includes(shopId)) return true;

      return false;
    })[0];
    return marker || null;
  }

  // 指定のピンが非表示の複数用ピンに含まれる店舗かどうか
  #isIncludeHiddenPositionMarker(marker) {
    if (!marker) return false;

    const shopIds = this.#getShopIdsFromMarker(marker);
    const hiddenShopIds = this.#getShopIdsFromMarker(this.hiddenSamePositionMarker);
    return hiddenShopIds.includes(shopIds[0]);
  }

  /**
   * 表示中のピンから指定された店舗IDが含まれる複数用ピンのアイコンURLを取得する
   * @returns {string} - アイコンのURL。ピンが定義されていない場合やアイコンのURLが存在しない場合は空文字列。
   */
  #getIconUrlFromActiveMarker() {
    // ピン画像未定義の対策
    const iconURL = this.activeMarker && this.activeMarker.icon ? this.activeMarker.icon.url : "";
    return iconURL
  }

  /**
   * ピンに設定されているクラスから店舗IDの配列を取得する
   * @param {Marker} marker - マーカーオブジェクト
   * @returns {number[]} - 店舗IDの配列。マーカーオブジェクトが未定義の場合は空の配列。
   */
  #getShopIdsFromMarker(marker) {
    if (!marker) return [];

    const shopIdsStr = marker.label.className.match(/shop_[1-9]\d*|0/g);
    return shopIdsStr.map(shopIdStr => Number(shopIdStr.replace("shop_", "")));
  }

  // マップがクリックされたらピンを非アクティブにし、詳細窓・検索窓を閉じる
  #mapClickListener() {
    this.map.addListener("click", () => {
      const activeMarkerIconURL = this.#getIconUrlFromActiveMarker();
      if (this.activeMarker && activeMarkerIconURL) {
        // 複数ピンの中身以外であればクラスタ内に戻す
        if (!this.#isIncludeHiddenPositionMarker(this.activeMarker)) {
          this.markerClusterer.addMarker(this.activeMarker);
        }
        // アクティブなピンがあれば元の状態に戻す
        this.activeMarker.setZIndex(this.activeMarkerAfterZIndex);
        this.#setMarkerIcon(this.activeMarker, activeMarkerIconURL);
      }
      if (this.hiddenSamePositionMarker && this.#isIncludeHiddenPositionMarker(this.activeMarker)) {
        // 非表示にしていた複数ピンを再表示
        this.#uniteSamePositionMarker();
      }
      this.#closeDetailWindow();
      this.closeSameAddressWindow(true);
      this.#closeSearchWindow();
      this.activeMarker = null;
    });
  }

  // 地図のドラッグと拡大縮小を監視して再取得
  #loadDragAndZoomListener() {
    const self = this;
    // ドラッグまたは拡大縮小後に地図がアイドル状態になると発火
    this.map.addListener( "idle", async function () {
      if (self.loadedByBrowser) {
        // 最初のブラウザ読み込み時もアイドル判定になるのでreturn
        self.loadedByBrowser = false;
        return;
      }

      self.#setSearchCenterPosition();
      self.#setSearchZoomLevel();
      self.#setSearchRadius();

      // panToによる地図移動の場合はreturn
      if (self.isPanTo) {
        self.isPanTo = false;
        return;
      }

      const pos = self.map.getCenter();
      const center = [pos.lat(), pos.lng()];
      const originCenter = [self.originCenterPosition.latitude, self.originCenterPosition.longitude]
      const dragDistanceInKm = geofire.distanceBetween(originCenter, center);
      const shortSideDistanceInKm = self.#getDisplayShortSideKM();
      // 画面の短辺の距離より移動したら再検索ボタンを表示
      if (shortSideDistanceInKm < dragDistanceInKm) {
        self.searchNewAreaButtonTarget.style.display = "block";
        self.showedSearchNewAreaButton = true; // 表示済み判定
      }

      // 一定のズームより引きの場合はクラスタ・検索窓やボタンを非表示
      if (self.map.getZoom() <= 8) {
        if (self.markerClusterer.markers.length > 0) {
          const activeShopIds = self.#getShopIdsFromMarker(self.activeMarker);
          if (activeShopIds.length >= 2) {
            // 複数ピンがアクティブなら元に戻す
            const activeMarkerIconURL = self.#getIconUrlFromActiveMarker();
            self.activeMarker.setZIndex(self.activeMarkerAfterZIndex);
            self.#setMarkerIcon(self.activeMarker, activeMarkerIconURL);
          }
          // 一旦複数ピン表示に切り替える
          self.#uniteSamePositionMarker();
          // クラスタを非表示にする
          self.hiddenClusters = [...self.markerClusterer.markers];
          self.markerClusterer.clearMarkers();
        }
        self.openSearchWindowButtonPcTarget.style.display = "none";
        self.searchNewAreaButtonTarget.style.display = "none";
        self.closeSameAddressWindow(true);
        self.#closeSearchWindow();
        self.searchWindowTarget.style.display = "none"; // closeにするだけだとスマホで導線が残るので非表示に
        self.#openZoomAlert();
        self.closeLimitAlert();

      } else {
        if (self.hiddenClusters.length > 0) {
          // クラスタを再表示する
          self.markerClusterer.addMarkers(self.hiddenClusters);
          self.hiddenClusters = [];
        }
        if (self.showedSearchNewAreaButton) {
          // このエリアを検索：一度でも表示されていた場合は表示する
          self.searchNewAreaButtonTarget.style.display = "block";
        }
        if (self.showedLimitAlert) {
          // 制限アラートが表示中だった場合は再度表示する
          self.#openLimitAlert();
        }
        self.openSearchWindowButtonPcTarget.style.display = "flex";
        self.closeZoomAlert();
        self.searchWindowTarget.style.display = "block";
      }
    });
  }

  // ピンを実体化して開く
  #getMarker(position, shopIds, icon, labelContent, categoryNameEn, zIndex = 1) {
    const labelOffset = categoryNameEn === "same" ? 0 : 5;
    const shopIdsString = shopIds.map(shopId => `shop_${shopId}`).join(" ");

    return new MarkerWithLabel({
      position,
      map: this.map,
      icon: {
        url: icon,
        scaledSize: new google.maps.Size(MARKER_ICON_SIZE_WIDTH, MARKER_ICON_SIZE_HEIGHT),
      },
      labelContent,
      labelClass: `markerLabel is_${categoryNameEn} ${shopIdsString}`,
      labelAnchor: new google.maps.Point(-55 + labelOffset, 0), // デザインの関係で中心がズレるので+5する
      zIndex
    });
  }

  // ピンのアイコンを設定する
  #setMarkerIcon(marker, iconURL, isActive = false) {
    const origin = isActive && iconURL !== samePositionShopsImgUrl ? new google.maps.Point(3, 0) : new google.maps.Point(0, 0);
    const scale = isActive ? 1.5 : 1;
    marker.setIcon({
      url: iconURL,
      origin,
      scaledSize: new google.maps.Size(MARKER_ICON_SIZE_WIDTH * scale, MARKER_ICON_SIZE_HEIGHT * scale)
    });
  }

  // ピンを拡大表示にする
  #setActiveMarker(marker, pinIconUrl) {
    this.#setMarkerIcon(marker, pinIconUrl, true);
    this.activeMarker = marker;
    marker.setZIndex(ACTIVE_MARKER_ZINDEX); // クリックしたものが一番上になるように
    this.activeMarkerAfterZIndex += 1; // 元の大きさにピンを戻したときのz-index
  }

  /**
   * 地図表示領域の半径（キロメートル）を取得する
   * @returns {number} - 地図表示領域の半径（キロメートル）
   */
  #getDisplayRadiusKM() {
    const mapBounds = this.map.getBounds();
    const southWest = mapBounds.getSouthWest(); // 地図左下角の座標
    const northEast = mapBounds.getNorthEast(); // 地図右上角の座標
    const displayDistanceKM = geofire.distanceBetween(
      [southWest.lat(), southWest.lng()],
      [northEast.lat(), northEast.lng()]
    ); // 表示されている地図の左下角から右上角までの距離
    return displayDistanceKM / 2;
  }

  /**
   * 地図表示領域の短辺の距離（キロメートル）を取得する
   * @returns {number} - 地図表示領域の短辺の距離（キロメートル）
   */
  #getDisplayShortSideKM() {
    const mapBounds = this.map.getBounds();
    const center = mapBounds.getCenter(); // 地図中心の座標
    const northEast = mapBounds.getNorthEast(); // 地図右上角の座標

    // 表示されている地図の縦幅の距離
    const displayVerticalDistanceKM = geofire.distanceBetween(
      [center.lat(), center.lng()],
      [northEast.lat(), center.lng()]
    ) * 2;
    // 表示されている地図の横幅の距離
    const displayHorizontalDistanceKM = geofire.distanceBetween(
      [center.lat(), center.lng()],
      [center.lat(), northEast.lng()]
    ) * 2;
    return Math.min(displayVerticalDistanceKM, displayHorizontalDistanceKM);
  }

  // 店舗詳細窓の表示に必要な情報を挿入し、is_activeクラスを付与
  async #openDetailWindow(shopId, categoryNameEn, shopImageUrl, marker) {
    // タブを基本情報に切り替え
    this.changeDetailTab();

    // 検索窓・同一住所窓を閉じる
    this.closeSameAddressWindow(true);
    this.#closeSearchWindow();
    this.detailWindowTarget.style.zIndex = 20;

    // カテゴリ class付与（前回表示時のclassを削除してから付与）
    this.detailHeaderCategoryTarget.classList.remove(...this.detailHeaderCategoryTarget.classList);
    this.detailHeaderCategoryTarget.classList.add("category", `is_${categoryNameEn}`);

    // 店頭にいる日時を一旦非表示（店舗情報次第で必要に応じて表示）
    this.detailWindowWorkingHoursSectionTarget.style.display = "none";

    // 検索時のみis_searchingクラスを追加
    if (this.#getParam("is_search")) {
      this.detailWindowTarget.classList.add("shop_info", "js_shop_info", "is_searching");
    } else {
      this.detailWindowTarget.classList.add("shop_info", "js_shop_info");
    }

    // トップ画像
    if (shopImageUrl) {
      this.detailHeaderImgTarget.src = shopImageUrl;
    } else {
      this.detailHeaderImgTarget.src = noImgUrl;
    }

    // ローディング開始（ローディング以外の情報は非表示に）
    this.detailWindowTitleTarget.style.display = "none";
    this.detailWindowInfolistTarget.style.display = "none";
    this.detailWindowErrorTarget.style.display = "none";
    this.detailWindowLoadingTarget.style.display = "block";

    // 店舗詳細窓を開く
    this.detailArrowTarget.style.display = "grid";
    this.detailWindowTarget.classList.add("is_active");
    this.detailContainerTarget.scrollTop = this.detailWindowScroll;

    // サイドの窓を考慮した上での表示領域の中心にピンが来るよう移動
    // CSSのアニメーションの関係で正しい位置が取得できるまでディレイがあるので遅らせている
    const self = this;
    setTimeout(function() {
      self.#panToDisplayCenterArea(marker, true);
    }, 200);

    // 残りの情報を取得する
    let shop;
    try {
      shop = await this.#fetchShopDetail(shopId);
    } catch {
      // ローディング終了＆エラーの場合の表示
      this.detailWindowLoadingTarget.style.display = "none";
      this.detailWindowErrorTarget.style.display = "block";
      return;
    }

    // お気に入り
    this.bookmarkCountTarget.textContent = shop.bookmarkCount;
    this.bookmarkButtonTarget.setAttribute("data-bookmark-update-shop-id-param", shopId);
    if (shop.bookmarked) {
      this.bookmarkButtonTarget.classList.add("is_active");
      this.bookmarkTooltipTarget.textContent = 'お気に入りを解除';
    } else {
      this.bookmarkButtonTarget.classList.remove("is_active");
      this.bookmarkTooltipTarget.textContent = 'お気に入りを登録';
    }

    // タイトル・住所・営業時間・定休日・店頭にいる日時・予算・店舗紹介
    this.titleTarget.textContent = shop.name;
    this.titleTarget.setAttribute("href", `/shops/${shop.id}`);
    this.addressTarget.innerHTML = this.#displayAddress(shop, true);
    this.businessHoursTarget.textContent = shop.businessHours;
    this.holidayTarget.textContent = shop.holiday;
    if (shop.isWorking) {
      this.detailWindowWorkingHoursSectionTarget.style.display = "block";
      this.workingHoursTarget.textContent = shop.workingHours;
    }
    if (shop.budgetMin !== null && shop.budgetMax !== null) { // 0があるのでnot nullで判定
      this.budgetTarget.classList.remove("text_empty");
      const budgetMin = shop.budgetMin > 0 ? `${shop.budgetMin.toLocaleString()}円` : "下限なし";
      const budgetMax = shop.budgetMax < 99999999 ? `${shop.budgetMax.toLocaleString()}円` : "上限なし";
      this.budgetTarget.textContent = `${budgetMin}〜${budgetMax}`;
    } else {
      this.budgetTarget.classList.add("text_empty");
      this.budgetTarget.textContent = "登録されていません";
    }
    if (shop.description) {
      this.descriptionTarget.classList.add("more_text");
      this.descriptionTarget.classList.remove("text_empty");
      this.descriptionTarget.textContent = shop.description;
    } else {
      this.descriptionTarget.classList.remove("more_text");
      this.descriptionTarget.classList.add("text_empty");
      this.descriptionTarget.textContent = "詳細はありません";
    }

    // URL（前回表示時の要素を削除してから新たに作成）
    this.shopUrlRowTargets.forEach(shopUrl => shopUrl.remove());
    const urls = shop.mapShopUrls.map(mapShopUrl => mapShopUrl.url);
    urls.forEach(url => {
      // 存在するURLの数だけ要素を作成して挿入
      const urlRow = document.createElement('dd');
      urlRow.classList.add("text");
      urlRow.dataset.multipleMapTarget = "shopUrlRow"
      const shopLink = document.createElement('a');
      shopLink.classList.add("text", "text_link");
      shopLink.setAttribute('target', '_blank');
      shopLink.setAttribute('rel', 'noopener nofollow');
      shopLink.textContent = url;
      shopLink.setAttribute("href", url);
      urlRow.appendChild(shopLink);
      this.shopUrlAreaTarget.appendChild(urlRow);
    })

    // 掲載者情報
    this.userIconTarget.src = shop.commonUser.icon;
    this.userIconTarget.setAttribute("alt", shop.commonUser.name);
    this.userNameTarget.textContent = shop.commonUser.name;
    this.userNameTarget.classList.remove("is_owner");
    if (shop.isOwner) {
      this.userNameTarget.classList.add("is_owner");
    }
    this.userProfileLinkTarget.setAttribute("href", `${gon.global.libecityUrl}/user_profile/${shop.commonUser.uid}`);
    this.displayRankTarget.textContent = shop.commonUser.displayRank;
    this.displayRankTarget.classList.remove(...this.displayRankTarget.classList);
    const displayRankIconClasses = ["mx-auto"];
    if (shop.commonUser.displayRankIcon) {
      displayRankIconClasses.push(shop.commonUser.displayRankIcon)
    }
    this.displayRankTarget.classList.add(...displayRankIconClasses);
    this.detailLinkPcTarget.setAttribute("href", `/shops/${shop.id}`);
    this.detailLinkSpTarget.setAttribute("href", `/shops/${shop.id}`);
    this.mapLinkSpTarget.setAttribute("href", `https://www.google.com/maps/search/?api=1&query=${shop.latitude},${shop.longitude}`);

    // 口コミタブ
    this.reviewTabTarget.innerHTML = `<i class="fa-solid fa-comment-dots"></i>口コミ(${shop.reviewCount})`;
    this.detailTabsTarget.style.display = "flex";
    // リストをリセットして空にする
    this.reviewListItemTargets.forEach(target => target.remove());
    shop.mapReviews.forEach(review => this.#insertReviewList(review));
    if (shop.mapReviews.length === 0) {
      const noReview = document.createElement('div');
      noReview.classList.add("noReview");
      noReview.textContent = "まだ口コミがありません";
      noReview.dataset.multipleMapTarget = "reviewListItem";
      this.reviewListTarget.appendChild(noReview);
    }

    // ローディング終了
    this.detailWindowLoadingTarget.style.display = "none";
    this.detailWindowTitleTarget.style.display = "flex";

    this.detailWindowInfolistTarget.style.display = "block";

    // 店舗説明の内容があれば続きを読むが表示されるようにする（toggle-more-descriptionコントローラーのものを使う）
    this.element["toggle-more-description"].shopToggleButtonIfUsed();
  }

  // 同住所用の店舗詳細窓の表示に必要な情報を挿入し開く
  async openSameAddressDetailWindow(e) {
    const categoryNameEn = e.params.payload.categoryNameEn;
    const shopId = e.params.payload.shopId;
    const shopImageUrl = e.params.payload.shopImageUrl;

    // カテゴリ class付与（前回表示時のclassを削除してから付与）
    this.sameAddressDetailHeaderCategoryTarget.classList.remove(...this.sameAddressDetailHeaderCategoryTarget.classList);
    this.sameAddressDetailHeaderCategoryTarget.classList.add("category", `is_${categoryNameEn}`);

    // 店頭にいる日時を一旦非表示（店舗情報次第で必要に応じて表示）
    this.sameAddressWorkingHoursSectionTarget.style.display = "none";

    // トップ画像
    if (shopImageUrl) {
      this.sameAddressDetailHeaderImgTarget.src = shopImageUrl;
    } else {
      this.sameAddressDetailHeaderImgTarget.src = noImgUrl;
    }

    // ローディング開始（ローディング以外の情報は非表示に）
    this.sameAddressDetailWindowTitleTarget.style.display = "none";
    this.sameAddressDetailWindowInfolistTarget.style.display = "none";
    this.sameAddressDetailWindowErrorTarget.style.display = "none";
    this.sameAddressDetailWindowLoadingTarget.style.display = "block";

    // 店舗詳細窓を開く
    this.sameAddressShopListContainerTarget.classList.add("is_hide");
    this.sameAddressDetailContainerTarget.classList.remove("is_hide");

    // 情報を取得
    let shopData;
    try {
      shopData = await this.#fetchShopDetail(shopId);
    } catch {
      // ローディング終了＆エラーの場合の表示
      this.sameAddressDetailWindowLoadingTarget.style.display = "none";
      this.sameAddressDetailWindowErrorTarget.style.display = "block";
      return;
    }

    // 複数ピンを個別表示にする
    const samePositionMarker = this.#getSamePositionMarkerByShopId(shopId);
    this.#individualizationSamePositionMarker(samePositionMarker);
    // 個別のピンを取得
    const currentMarker = this.#getOpenedMarkerByShopId(shopId);

    // サイドの窓を考慮した上での表示領域の中心にピンが来るよう移動
    this.#panToDisplayCenterArea(currentMarker);

    const activeMarkerIconURL = this.#getIconUrlFromActiveMarker();
    if (this.activeMarker && activeMarkerIconURL) {
      // アクティブなピンがあれば元の状態に戻す
      this.activeMarker.setZIndex(this.activeMarkerAfterZIndex);
      this.#setMarkerIcon(this.activeMarker, activeMarkerIconURL);
    }

    // クリックされたピンを拡大表示にする
    this.#setActiveMarker(currentMarker, shopData.pinIconUrl);

    // タイトル・住所・営業時間・定休日・店頭にいる日時・予算・店舗紹介
    this.sameAddressTitleTarget.textContent = shopData.name;
    this.sameAddressTitleTarget.setAttribute("href", `/shops/${shopData.id}`);
    this.sameAddressAddressTarget.textContent = this.#displayAddress(shopData);
    this.sameAddressBusinessHoursTarget.textContent = shopData.businessHours;
    this.sameAddressHolidayTarget.textContent = shopData.holiday;
    if (shopData.isWorking) {
      this.sameAddressWorkingHoursSectionTarget.style.display = "block";
      this.sameAddressWorkingHoursTarget.textContent = shopData.workingHours;
    }
    if (shopData.budgetMin !== null && shopData.budgetMax !== null) { // 0があるのでnot nullで判定
      this.sameAddressBudgetTarget.classList.remove("text_empty");
      const budgetMin = shopData.budgetMin > 0 ? `${shopData.budgetMin.toLocaleString()}円` : "下限なし";
      const budgetMax = shopData.budgetMax < 99999999 ? `${shopData.budgetMax.toLocaleString()}円` : "上限なし";
      this.sameAddressBudgetTarget.textContent = `${budgetMin}〜${budgetMax}`;
    } else {
      this.sameAddressBudgetTarget.classList.add("text_empty");
      this.sameAddressBudgetTarget.textContent = "登録されていません";
    }
    if (shopData.description) {
      this.sameAddressDescriptionTarget.classList.add("more_text");
      this.sameAddressDescriptionTarget.classList.remove("text_empty");
      this.sameAddressDescriptionTarget.textContent = shopData.description;
    } else {
      this.sameAddressDescriptionTarget.classList.remove("more_text");
      this.sameAddressDescriptionTarget.classList.add("text_empty");
      this.sameAddressDescriptionTarget.textContent = "詳細はありません";
    }

    // URL（前回表示時の要素を削除してから新たに作成）
    this.sameAddressShopUrlRowTargets.forEach(shopUrl => shopUrl.remove());
    const urls = shopData.mapShopUrls.map(mapShopUrl => mapShopUrl.url);
    urls.forEach(url => {
      // 存在するURLの数だけ要素を作成して挿入
      const urlRow = document.createElement('dd');
      urlRow.classList.add("text");
      urlRow.dataset.multipleMapTarget = "sameAddressShopUrlRow"
      const shopLink = document.createElement('a');
      shopLink.classList.add("text", "text_link");
      shopLink.setAttribute('target', '_blank');
      shopLink.setAttribute('rel', 'noopener nofollow');
      shopLink.textContent = url;
      shopLink.setAttribute("href", url);
      urlRow.appendChild(shopLink);
      this.sameAddressShopUrlAreaTarget.appendChild(urlRow);
    })

    // 掲載者情報
    this.sameAddressUserIconTarget.src = shopData.commonUser.icon;
    this.sameAddressUserIconTarget.setAttribute("alt", shopData.commonUser.name);
    this.sameAddressUserNameTarget.textContent = shopData.commonUser.name;
    this.sameAddressUserNameTarget.classList.remove("is_owner");
    if (shopData.isOwner) {
      this.sameAddressUserNameTarget.classList.add("is_owner");
    }
    this.sameAddressUserProfileLinkTarget.setAttribute("href", `${gon.global.libecityUrl}/user_profile/${shopData.commonUser.uid}`);
    this.sameAddressDisplayRankTarget.textContent = shopData.commonUser.displayRank;
    this.sameAddressDisplayRankTarget.classList.remove(...this.sameAddressDisplayRankTarget.classList);
    const displayRankIconClasses = ["mx-auto"];
    if (shopData.commonUser.displayRankIcon) {
      displayRankIconClasses.push(shopData.commonUser.displayRankIcon)
    }
    this.sameAddressDisplayRankTarget.classList.add(...displayRankIconClasses);
    this.sameAddressDetailLinkTarget.setAttribute("href", `/shops/${shopData.id}`);

    // ローディング終了
    this.sameAddressDetailWindowLoadingTarget.style.display = "none";
    this.sameAddressDetailWindowTitleTarget.style.display = "flex";
    this.sameAddressDetailWindowInfolistTarget.style.display = "block";

    // 店舗説明の内容があれば続きを読むが表示されるようにする（toggle-more-descriptionコントローラーのものを使う）
    this.element["toggle-more-description"].shopToggleButtonIfUsed();
  }

  // 同住所の詳細窓を閉じる
  closeSameAddressDetailWindow() {
    // 続きを読むを閉じる（toggle-more-descriptionコントローラーのものを使う）
    this.element["toggle-more-description"].closeAllMoreDescription();

    this.sameAddressDetailContainerTarget.classList.add("is_hide");
    this.sameAddressShopListContainerTarget.classList.remove("is_hide");
  }

  // 同住所の詳細窓を閉じ、複数ピンを再表示する
  closeSameAddressDetailWindowAndShowSameAddressMarker() {
    this.closeSameAddressDetailWindow();

    // 非表示にしていた複数ピンを再表示
    this.#uniteSamePositionMarker(true);
  }

  // 詳細窓を閉じる
  #closeDetailWindow() {
    // 続きを読むを閉じる（toggle-more-descriptionコントローラーのものを使う）
    if (this.element["toggle-more-description"]) {
      // ブラウザ読み込み時にundefinedになってエラーになる＆読み込み時は処理に入らなくていいので対策
      this.element["toggle-more-description"].closeAllMoreDescription();
    }
    this.changeDetailTab();

    this.detailWindowScroll = this.detailContainerTarget.scrollTop;
    this.detailWindowTarget.classList.remove("is_active");
    this.detailArrowTarget.style.display = "none";
    this.detailWindowTarget.style.zIndex = 10;
  }

  // 店舗詳細窓矢印クリック時：詳細窓が閉じていれば開く・開いていれば閉じる
  toggleDetailWindow() {
    if (this.detailWindowTarget.classList.contains("is_active")) {
      // 閉じる
      this.detailWindowScroll = this.detailContainerTarget.scrollTop;
      this.detailWindowTarget.classList.remove("is_active");
      return;
    }
    // 開く
    this.detailWindowTarget.classList.add("is_active");
    this.detailContainerTarget.scrollTop = this.detailWindowScroll;
  }

  // 検索窓を開く
  openSearchWindow(isDefault = false) {
    this.#closeDetailWindow();

    this.#changeIsOpenSearchWindowValue(true);
    if (isDefault === true) { // フロントからの実行時にeventが引数に入ってしまうのでbool値であるかを正しく確認
      this.searchWindowTarget.classList.add("is_default");
    } else {
      this.searchWindowTarget.classList.remove("is_default");
    }
    this.searchWindowTarget.style.zIndex = 20;
    this.searchWindowTarget.classList.add("is_active");
    this.searchContainerTarget.scrollTop = this.searchWindowScroll;
    this.searchArrowTarget.style.display = "grid";
  }

  // 検索窓を閉じる
  #closeSearchWindow() {
    this.#changeIsOpenSearchWindowValue(false);
    this.searchWindowScroll = this.searchContainerTarget.scrollTop;
    this.searchWindowTarget.classList.remove("is_default");
    this.searchWindowTarget.classList.remove("is_active");
    this.searchArrowTarget.style.display = "none";
    this.searchWindowTarget.style.zIndex = 10;
  }

  // 検索窓矢印クリック時：検索窓が閉じていれば開く・開いていれば閉じる
  toggleSearchWindow() {
    this.searchWindowTarget.classList.remove("is_default");

    if (this.searchWindowTarget.classList.contains("is_active")) {
      // スクロール・開閉状態を保持して閉じる
      this.searchWindowScroll = this.searchContainerTarget.scrollTop;
      this.searchWindowTarget.classList.remove("is_active");
      this.#changeIsOpenSearchWindowValue(false);
      return;
    }
    // 開いて元の位置にスクロール
    this.searchWindowTarget.classList.add("is_active");
    this.searchContainerTarget.scrollTop = this.searchWindowScroll;
    this.#changeIsOpenSearchWindowValue(true);
  }

  /**
   * 同一住所ウィンドウを開く
   * @param {number[]} shopIds - 店舗IDの配列
   * @param {Marker} marker - 対象のマーカーオブジェクト
   */
  async openSameAddressWindow(shopIds, marker) {
    this.closeSameAddressDetailWindow();
    this.#closeDetailWindow();
    this.openSearchWindow();
    // リストをリセットして空にする
    this.sameAddressShopListItemTargets.forEach(target => target.remove());
    this.sameAddressShopCountTarget.innerText = `（${shopIds.length}件）`

    this.sameAddressWindowErrorTarget.style.display = "none";
    this.searchArrowTarget.style.display = "none";
    this.sameAddressWindowTarget.classList.add("is_active");
    this.sameAddressWindowCloseArrowTarget.style.display = "block";

    // サイドの窓を考慮した上での表示領域の中心にピンが来るよう移動
    // CSSのアニメーションの関係で正しい位置が取得できるまでディレイがあるので遅らせている
    const self = this;
    setTimeout(function() {
      self.#panToDisplayCenterArea(marker);
    }, 200);

    // ローディング開始
    this.sameAddressWindowLoadingTarget.style.display = "block";

    let sameAddressShops;
    try {
      sameAddressShops = await this.#fetchSameAddressShops(shopIds);
    } catch {
      // ローディング終了＆エラーの場合の表示
      this.sameAddressWindowLoadingTarget.style.display = "none";
      this.sameAddressWindowErrorTarget.style.display = "block";
      return;
    }

    // リストに同住所の一覧を表示する
    sameAddressShops.forEach(sameAddressShop => {
      this.#insertSameAddressShopList(sameAddressShop);
    });

    // ローディング終了
    this.sameAddressWindowLoadingTarget.style.display = "none";
  }

  // 同住所窓を閉じる
  closeSameAddressWindow(e) {
    // 続きを読むを閉じる（toggle-more-descriptionコントローラーのものを使う）
    this.element["toggle-more-description"].closeAllMoreDescription();

    const withSearchWindow = e.params ? JSON.parse(e.params.payload.withSearchWindow.toLowerCase()) : true;
    this.sameAddressWindowTarget.classList.remove("is_active");
    this.sameAddressWindowCloseArrowTarget.style.display = "none";
    if (!withSearchWindow) {
      this.searchArrowTarget.style.display = "grid";
    }
  }

  // ページ遷移時に一覧最上部にスクロールする
  scrollTopOnPaginate() {
    this.searchContainerTarget.scrollTop = 0;
  }

  /**
   * 店舗単体の情報を取得する
   * @param {number} shopId - 取得する店舗のID
   * @return {Promise<Object>} - 店舗データのオブジェクト
   */
  async #fetchShopDetail(shopId) {
    const result = await fetcher.get(`/api/internal/v1/get_shop?shop_id=${shopId}`);
    return result.data;
  }

  /**
   * 同一住所ウィンドウ用の情報を取得する
   * @param {number[]} shopIds - 取得する店舗のID配列
   * @return {Promise<Object[]>} - 店舗データの配列
   */
  async #fetchSameAddressShops(shopIds) {
    const result = await fetcher.get(`/api/internal/v1/get_shops?shop_ids=${shopIds}`);
    return result.data;
  }

  // クリックした該当の店舗の位置に地図を移動し、詳細窓を開く
  async moveToShop(e) {
    this.searchNewAreaButtonTarget.style.display = "none";

    // 表示済みのピン（クラスタ）からリスト上でクリックされた店舗のピンを取得
    const match = e.currentTarget.className.match(/shop_[1-9]\d*|0/g);
    const clickedShopId = Number(match[0].replace("shop_", "")); // ここで複数件該当するパターンはないので1件のみ抽出

    // クリックされた店舗がクラスタ内にあるか検索
    const currentCluster = this.markerClusterer.clusters.find(cluster => {
      return cluster.markers.some(marker => {
        const shopIds = this.#getShopIdsFromMarker(marker);
        return shopIds.includes(clickedShopId);
      });
    });
    // クラスタ表示（2件以上）になっているピンはズームする
    if (currentCluster && currentCluster.markers.length >= 2) {
      // 選択したクラスターの境界を取得する
      const clusterBounds = currentCluster.bounds;
      // 地図をクラスターの境界に合わせてズームする
      this.map.fitBounds(clusterBounds);
    }

    const samePositionMarker = this.#getSamePositionMarkerByShopId(clickedShopId);
    const activeMarkerShopIds = this.#getShopIdsFromMarker(this.activeMarker);
    // すでにアクティブなピンの該当する店舗がクリックされたかどうかを判別
    const isClickedActiveShopId = activeMarkerShopIds.length === 1 ? clickedShopId === activeMarkerShopIds[0] : false;

    if (!isClickedActiveShopId && this.hiddenSamePositionMarker && this.#isIncludeHiddenPositionMarker(this.activeMarker)) {
      // 複数用のピンに該当しない店舗がクリックされた場合、非表示にしていた複数ピンを再表示
      this.#uniteSamePositionMarker();
    }
    if (!isClickedActiveShopId && samePositionMarker && !this.hiddenSamePositionMarker) {
      // 複数用のピンに該当する店舗がクリックされた場合、複数用のピンを非表示にする
      this.#individualizationSamePositionMarker(samePositionMarker);
    }
    const activeMarkerIconURL = this.#getIconUrlFromActiveMarker();
    if (this.activeMarker && activeMarkerIconURL) {
      // アクティブなピンが複数ピンの中身に該当する場合はaddMarkerしない
      if (!this.#isIncludeHiddenPositionMarker(this.activeMarker)) {
        this.markerClusterer.addMarker(this.activeMarker);
      }
      // アクティブなピンがあれば元の状態に戻す
      this.activeMarker.setZIndex(this.activeMarkerAfterZIndex);
      this.#setMarkerIcon(this.activeMarker, activeMarkerIconURL);
    }
    // 個別のピンを取得
    const currentMarker = this.#getOpenedMarkerByShopId(clickedShopId);
    const currentShops = this.nearShops.filter(nearShop => {
      return nearShop.shop_data.id === clickedShopId;
    })
    const currentShop = currentShops[0];
    const pinIconUrl = currentShop.shop_data.pin_icon_url;
    const shopImageUrl = currentShop.shop_image_url;
    const categoryNameEn = currentShop.category_name_en;
    this.#openDetailWindow(clickedShopId, categoryNameEn, shopImageUrl, currentMarker);
    // setMapされる前に処理が走ってエラーになることがあるので対策
    const self = this;
    setTimeout(function() {
      // クリックされたピンを拡大表示にする
      self.#removeFromCluster(currentMarker);
      self.#setActiveMarker(currentMarker, pinIconUrl);
    }, 150);
  }

  // 検索を実行
  submitSearchForm() {
    this.searchFormTarget.submit();
  }

  // すべての検索条件をリセット
  resetAllConditions() {
    this.resetKeyword();
    this.resetCategory();
    this.resetRank();
    this.resetWorking();
    this.resetOwner();
  }
  resetKeyword() {
    this.searchTextFieldPcTarget.value = "";
    this.searchTextFieldSpTarget.value = "";
    this.submitSearchForm();
  }
  resetCategory() {
    this.searchCategoryPcTargets.forEach(target => {
      if (target.value !== "") return;
      target.checked = true;
    });
    this.searchCategorySpTarget.value = "";
    this.submitSearchForm();
  }
  resetRank() {
    this.searchRankPcTargets.forEach(target => {
      if (target.value !== "") return;
      target.checked = true;
    });
    this.searchRankSpTargets.forEach(target => {
      if (target.value !== "") return;
      target.checked = true;
    });
    this.submitSearchForm();
  }
  resetWorking() {
    this.searchWorkingPcTarget.checked = false;
    this.searchWorkingSpTarget.checked = false;
    this.submitSearchForm();
  }
  resetOwner() {
    this.searchOwnerPcTarget.checked = false;
    this.searchOwnerSpTarget.checked = false;
    this.submitSearchForm();
  }

  selectSort(e) {
    // ソートのselectボックスがformタグ外にあるため、formタグ内にhiddenなsortのinputを置き書き換えている
    const sortValue = e.currentTarget.value;
    this.searchSortTarget.value = sortValue;
    if (this.#getParam("is_first")) {
      // 検索画面から遷移の初期表示の状態でソートした際は、再度is_firstを引き継ぐ
      const isFirstInput = document.createElement("input");
      isFirstInput.setAttribute("type", "hidden");
      isFirstInput.setAttribute("name", "is_first");
      isFirstInput.setAttribute("value", "true");
      isFirstInput.setAttribute("autocomplete", "off");
      this.searchSortTarget.insertAdjacentElement("afterend", isFirstInput);
    }
    this.submitSearchForm();
  }

  // カテゴリ選択をPC・SPのフォームで同期する
  syncCategoryValue(e) {
    this.searchCategoryPcTargets.forEach(target => {
      if (target.value !== e.target.value) return;
      target.checked = true;
    });
    this.searchCategorySpTarget.value = e.target.value;
  }

  // ランク選択をPC・SPのフォームで同期する
  syncRankValue(e) {
    this.searchRankPcTargets.forEach(target => {
      if (target.value !== e.target.value) return;
      target.checked = true;
    });
    this.searchRankSpTargets.forEach(target => {
      if (target.value !== e.target.value) return;
      target.checked = true;
    });
  }

  // 「会員が店頭にいる」チェック状態をPC・SPのフォームで同期する
  syncWorkingCheck(e) {
    this.searchWorkingPcTarget.checked = e.target.checked;
    this.searchWorkingSpTarget.checked = e.target.checked;
  }

  // 「会員が経営している」チェック状態をPC・SPのフォームで同期する
  syncOwnerCheck(e) {
    this.searchOwnerPcTarget.checked = e.target.checked;
    this.searchOwnerSpTarget.checked = e.target.checked;
  }

  // チェックの外れたinputのラベルにis_uncheckクラスを付与
  uncheckSearchRank(e) {
    const rankId = e.currentTarget.value ? Number(e.currentTarget.value) : "";
    this.searchRankSpTargets.forEach(target => {
      if (target.value != rankId) {
        target.parentNode.classList.add("is_uncheck");
        return;
      }
      target.parentNode.classList.remove("is_uncheck");
    });
  }

  // キーワードをPC・SPのフォームで同期しつつ入力削除ボタン表示切り替え
  showDeleteKeywordButton(e) {
    if (e) {
      this.searchTextFieldPcTarget.value = e.target.value;
      this.searchTextFieldSpTarget.value = e.target.value;
    }
    if (!this.searchTextFieldPcTarget.value) {
      this.searchTextFieldAreaPcTarget.classList.remove("is_search");
      this.searchTextFieldAreaSpTarget.classList.remove("is_search");
      return;
    }
    this.searchTextFieldAreaPcTarget.classList.add("is_search");
    this.searchTextFieldAreaSpTarget.classList.add("is_search");
  }
  // キーワードを削除する
  deleteKeyword() {
    this.searchTextFieldPcTarget.value = "";
    this.searchTextFieldSpTarget.value = "";
  }

  // 地図の中心の座標を再検索用にセット
  #setSearchCenterPosition() {
    const pos = this.map.getCenter();
    if (this.isMobile) {
      // スマホの場合は初期位置をズラしている関係で中心がずれるので調整
      this.searchLatitudeTarget.value = pos.lat() + CENTER_LATITUDE_OFFSET;
    } else {
      this.searchLatitudeTarget.value = pos.lat();
    }
    this.searchLongitudeTarget.value = pos.lng();
  }

  // 現在のズーム倍率を再検索用にセット
  #setSearchZoomLevel() {
    this.searchZoomLevelTarget.value = this.map.getZoom();
  }

  // 表示領域の半径を再検索用にセット
  #setSearchRadius() {
    if (!this.map.getBounds()) {
      const radiusKm = this.#getParam("radius_km");
      this.searchRadiusTarget.value = radiusKm ? radiusKm : 5;
      return;
    }
    this.searchRadiusTarget.value = this.#getDisplayRadiusKM();
  }

  // 現在地から取得した緯度経度を使用して検索
  async getPositionAndSubmit() {
    // WebViewで開いている場合、Flutterの処理を呼び出すためにJavascriptChannelにメッセージを送る
    if (typeof currentAddress !== "undefined") {
      // eslint-disable-next-line no-undef
      currentAddress.postMessage("");
    }
    // 全画面ローディング（full-screen-loadingコントローラーのものを使う）
    this.element["full-screen-loading"].submit();

    const position =  await getCurrentPositionAsync().catch(() => {
      // WebViewで開いている場合、currentAddressオブジェクトが定義されているため、FlutterのJavascriptChannelにメッセージを送る
      if (typeof currentAddress !== "undefined") {
        // eslint-disable-next-line no-undef
        currentAddress.postMessage("");
      } else {
        alert("位置情報の利用が許可されていません。\n詳細は「位置情報の利用を許可」をご確認ください。");
      }
      return null;
    });
    if (position === null) return;

    const { latitude, longitude } = position.coords;
    this.searchLatitudeTarget.value = latitude;
    this.searchLongitudeTarget.value = longitude;
    this.searchZoomLevelTarget.value = DEFAULT_ZOOM_LEVEL;
    this.submitSearchForm();
  }

  // Flutterで現在位置から取得した緯度経度を使用して検索
  async getPositionAndSubmitFromWebview() {
    const [latitude, longitude] = JSON.parse(this.currentCoordinateTarget.value).map((e) => Number(e));
    this.searchLatitudeTarget.value = latitude;
    this.searchLongitudeTarget.value = longitude;
    this.searchZoomLevelTarget.value = DEFAULT_ZOOM_LEVEL;
    this.submitSearchForm();
  }

  // サムネイルのカテゴリがクリックされたらカテゴリフォームを書き換えて送信
  clickCategoryOnThumb(e) {
    e.stopPropagation();
    const categoryId = e.params.payload.categoryId.toString();
    this.searchCategoryPcTargets.forEach(target => {
      if (target.value !== categoryId) return;
      target.checked = true;
    });
    this.submitSearchForm();
  }

  // 表示件数制限のアラートを表示する
  #openLimitAlert() {
    this.limitAlertTarget.style.display = "block";
    this.mapCloseButtonTarget.classList.add("is_over");
  }

  // 表示件数制限のアラートを非表示にする
  closeLimitAlert() {
    this.limitAlertTarget.style.display = "none";
    if (this.zoomAlertTarget.style.display === "none") {
      // ズームのアラートも非表示ならクラスを撤去
      this.mapCloseButtonTarget.classList.remove("is_over");
    }
  }

  // ズーム制限のアラートを表示にする
  #openZoomAlert() {
    this.zoomAlertTarget.style.display = "block";
    this.mapCloseButtonTarget.classList.add("is_over");
  }

  // ズーム制限のアラートを非表示にする
  closeZoomAlert() {
    this.zoomAlertTarget.style.display = "none";
    if (this.limitAlertTarget.style.display === "none") {
      // 件数制限のアラートも非表示ならクラスを撤去
      this.mapCloseButtonTarget.classList.remove("is_over");
    }
  }

  // マップを閉じるボタンが押された際の挙動
  async closeMap() {
    const fromTops = this.#getParam("from_tops");
    if (fromTops) {
      // トップページ経由であれば他パラメータは無視してトップページを表示
      this.mapCloseButtonTarget.setAttribute("href", `/`);
      location.href = `/`;
      return;
    }

    const shopId = this.#getParam("shop_id");
    if (shopId) {
      // 詳細ページ経由であれば他パラメータは無視して詳細ページを表示
      this.mapCloseButtonTarget.setAttribute("href", `/shops/${shopId}`);
      location.href = `/shops/${shopId}`;
      return;
    }

    let kenId = "";
    try {
      // マップに表示されている地域の都道府県IDと市区町村IDを取得
      const result = await fetcher.get("/api/internal/v1/current_address", {
        params: { latitude: this.searchLatitudeTarget.value, longitude: this.searchLongitudeTarget.value }
      });
      const address = result.data;
      kenId = address.kenId;
    } catch(e) {
      console.error(e);
    }
    // その他パラメータをセット
    const keyword = this.#getParam("keyword");
    const categoryId = this.#getParam("category_id");
    const rankId = this.#getParam("rank_id");
    const isWorking = this.#getParam("is_working");
    const isOwner = this.#getParam("is_owner");
    const sort = this.#getParam("sort");

    location.href = `/search?keyword=${keyword}&category_id=${categoryId}&rank_id=${rankId}&ken_id=${kenId}&is_working=${isWorking}&is_owner=${isOwner}&sort=${sort}`;
  }

  /**
   * 住所を表示用に変換する
   * @param {Object} shop - 店舗データ
   * @return {string} - 表示用の住所
   */
  #displayAddress(shop, withCopyElement = false) {
    const fillZip = String(shop.zip).padStart(7, "0");
    const zip = `${fillZip.slice(0, 3)}-${fillZip.slice(3, 7)}`;
    const address = `${shop.commonAddress.kenName}${shop.commonAddress.cityName}${shop.townText}`;
    if (withCopyElement) {
      return `${zip}\n<span data-text-copy-target="copyText">${address}</spen>`;
    }
    return `${zip}\n${address}`;
  }

  /**
   * URLのパラメータの値を取得する
   * @param {string} name - パラメータ名
   * @return {string|boolean} - パラメータの値
   */
  #getParam(name) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';

    const str = results[2].replace(/\+/g, " ");
    if (str === "true" || str === "false") {
      return JSON.parse(str);
    }
    return str;
  }

  // 同住所の店舗一覧のリスト要素単体を生成し挿入する
  #insertSameAddressShopList(shop) {
    const template = this.sameAddressShopListItemTempLateTarget;
    const clone = template.content.cloneNode(true);

    const shopListItem = clone.querySelector('[data-multiple-map-target="sameAddressShopListItem"]');
    shopListItem.dataset.multipleMapPayloadParam = `{"shopId": ${shop.id}, "categoryNameEn": "${shop.mapCategory.nameEn}", "shopImageUrl": "${shop.topImageUrl}"}`;
    // 店舗画像
    const shopImg = clone.querySelector('[data-multiple-map-target="sameAddressShopImage"]');
    shopImg.src = shop.topImageUrl || noImgUrl;
    shopImg.alt = shop.name;
    // 店舗名
    const shopName = clone.querySelector('[data-multiple-map-target="sameAddressShopName"]');
    shopName.textContent = shop.name;
    // 住所
    const shopAddress = clone.querySelector('[data-multiple-map-target="sameAddressShopAddress"]');
    shopAddress.appendChild(document.createTextNode(`${shop.commonAddress.kenName}${shop.commonAddress.cityName}`));
    // ユーザーアイコン
    const userIcon = clone.querySelector('[data-multiple-map-target="sameAddressShopUserIcon"]');
    userIcon.src = shop.commonUser.icon;
    userIcon.alt = shop.commonUser.name;
    // ランクアイコン
    const rankIcon = clone.querySelector('[data-multiple-map-target="sameAddressShopUserRankIcon"]');
    const displayRankIconClasses = ["mx-auto"];
    if (shop.commonUser.displayRankIcon) {
      displayRankIconClasses.push(shop.commonUser.displayRankIcon)
    }
    rankIcon.classList.add(...displayRankIconClasses);
    // ユーザー名
    const userName = clone.querySelector('[data-multiple-map-target="sameAddressShopUserName"]');
    userName.textContent = shop.commonUser.name;
    // カテゴリ名
    const categoryWrap = clone.querySelector('[data-multiple-map-target="sameAddressShopCategoryWrap"]');
    categoryWrap.dataset.multipleMapPayloadParam = `{"categoryId": ${shop.categoryId}}`;
    const category = clone.querySelector('[data-multiple-map-target="sameAddressShopCategory"]');
    category.classList.add(`is_${shop.mapCategory.nameEn}`);

    // 生成した要素をページに追加
    this.sameAddressShopListTarget.appendChild(clone);
  }

  // 詳細窓の口コミ一覧の要素単体を生成し挿入する
  #insertReviewList(review) {
    const template = this.reviewListItemTempLateTarget;
    const clone = template.content.cloneNode(true);

    // レビュワー情報
    const reviewerIcon = clone.querySelector('[data-multiple-map-target="reviewerIcon"]');
    reviewerIcon.src = review.reviewer.icon;
    reviewerIcon.alt = review.reviewer.name;
    const reviewerDisplayRank = clone.querySelector('[data-multiple-map-target="reviewerDisplayRank"]');
    if (review.reviewer.displayRankIcon) {
      reviewerDisplayRank.classList.add(review.reviewer.displayRankIcon);
    }
    const reviewerName = clone.querySelector('[data-multiple-map-target="reviewerName"]');
    reviewerName.textContent = review.reviewer.name;
    const reviewerProfileLinks = clone.querySelectorAll('a');
    reviewerProfileLinks.forEach(reviewerProfileLink => {
      reviewerProfileLink.setAttribute("href", `${gon.global.libecityUrl}/user_profile/${review.reviewer.uid}`);
    });

    // 口コミ
    const reviewBudget = clone.querySelector('[data-multiple-map-target="reviewBudget"]');
    const budget = review.budget || "登録なし";
    reviewBudget.textContent = `価格（1人あたり）：${budget}`;
    const reviewDate = clone.querySelector('[data-multiple-map-target="reviewDate"]');
    let date;
    let dateTitle;
    const createdAtDate = new Date(review.createdAt);
    const updatedAtDate = new Date(review.updatedAt);
    if (updatedAtDate.getTime() > createdAtDate.getTime()) {
      date = updatedAtDate;
      dateTitle = "更新日";
    } else {
      date = createdAtDate;
      dateTitle = "投稿日";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    reviewDate.textContent = `${dateTitle}：${year}/${month}/${day}`;
    const reviewComment = clone.querySelector('[data-multiple-map-target="reviewComment"]');
    reviewComment.textContent = review.comment;

    // 口コミ画像
    const reviewImageList = clone.querySelector('[data-multiple-map-target="reviewImageList"]');
    if (review.mapReviewImages.length === 0) {
      reviewImageList.remove();
    } else {
      // レビュー画像を生成してtemplateに挿入
      const slideImagesTemplate = clone.querySelector('[data-multiple-map-target="slideImages"]')
      review.mapReviewImages.forEach((reviewImage, i) => {
        const cloneList = clone.querySelector('[data-multiple-map-target="reviewImageTemplate"]').content.cloneNode(true);
        const reviewImg = document.createElement("img");
        reviewImg.src = reviewImage.url.thumb.url;
        reviewImg.alt = reviewImage.id;
        cloneList.querySelector("li").appendChild(reviewImg);
        cloneList.querySelector("li").dataset.sliderPayloadParam = `{"number": "${i}", "type": "reviewImage"}`;
        reviewImageList.appendChild(cloneList);

        const slideImg = document.createElement("img");
        slideImg.src = reviewImage.url.url;
        slideImg.alt = reviewImage.id;

        // template自体に正しく挿入はできないのでinnerHTMLで反映
        slideImagesTemplate.innerHTML += slideImg.outerHTML;
      });
    }
    // 生成した要素をページに追加
    this.reviewListTarget.appendChild(clone);
  }

  // 基本情報タブをクリックさせる
  changeDetailTab() {
    if (this.detailTabTarget.classList.contains("is_current")) return;
    this.detailTabTarget.click();
  }
  // 詳細窓を開く処理の関係でタブ切り替えのbootstrapが正しく動作しないので対策用処理
  showDetailWindowInfolist() {
    this.detailWindowInfolistTarget.style = "block";
  }
  hideDetailWindowInfolist() {
    this.detailWindowInfolistTarget.style = "none";
  }
  // 検索窓の開閉状態保持のパラメータを書き換える
  #changeIsOpenSearchWindowValue(boolean) {
    if (this.isMobile) {
      // スマホのみ開閉状態を保持
      this.isOpenSearchWindowTarget.value = boolean;
    }
  }
  // クラスタ化処理をする
  #generateCluster() {
    // クラスタと数字の大きさをデバイスで変える
    const fontSize = this.isMobile ? "15px" : "25px";
    const clusterSize = this.isMobile ? [50, 50] : [72, 72];
    const renderer = {
      render: ({ count, position }, stats) => {
        // クラスタの文字やアイコンを変更
        return new google.maps.Marker({
          label: { text: String(count), color: "#3A9053", fontSize},
          position,
          icon: {
            url: clusterImgUrl,
            scaledSize: new google.maps.Size(...clusterSize),
          },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      })}
    };
    // クラスタ化
    this.markerClusterer = new MarkerClusterer({
      markers: this.openedMarkers,
      map: this.map,
      renderer
    });
  }
}
