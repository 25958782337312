import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper/bundle";

// Connects to data-controller="slider"
export default class extends Controller {
  static targets = [
    "miniShopCard",
    "slideImages",
    "modalImageWrapper",
    "modalImageTemplate",
    "modalImage",
    "imageModal"
  ];

  connect() {
    this.#publisherOtherShopsSlider();
    this.#closeImageModalOnExternalClick();
    this.#spImageSlider();
  }

  // メイン画像のスライダー
  #mainImageSlider() {
    //サムネイル
    const sliderThumbnail = new Swiper(".slider-thumbnail", {
      slidesPerView: 10,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true
    });
    //スライダー
    this.mainSlider = new Swiper(".slider", {
      loop: false,
      spaceBetween: 5,
      navigation: {
        nextEl: ".swiper-button-next--image",
        prevEl: ".swiper-button-prev--image"
      },
      thumbs: {
        swiper: sliderThumbnail
      }
    });
  }

  // SP用スライダー
  #spImageSlider() {
    new Swiper(".sp_shop_image", {
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next--image",
        prevEl: ".swiper-button-prev--image",
      }
    });
  }

  // 掲載者欄のスライダー
  #publisherOtherShopsSlider() {
    let swiperOption;
    // Swiper:対象が1枚か複数か制御
    if (this.miniShopCardTargets.length === 1) {
      swiperOption = {
        loop: false,
        centeredSlides: true,
        SimulateTouch: false,
        preventClicksPropagation: false,
        preventClicks: false
      };
    } else {
      swiperOption = {
        loop: true,
        centeredSlides: true,
        slidesPerView: 1,
        SimulateTouch: false,
        preventClicksPropagation: false,
        speed: 500,
        autoplay: {
          delay: 4000
        },
        navigation: {
          nextEl: ".swiper-button-next--shop",
          prevEl: ".swiper-button-prev--shop"
        }
      };
    }
    new Swiper(".swiper-shop", swiperOption);
  }

  // 写真モーダルを開く
  showImageModal(e) {
    this.modalImageWrapperTargets.forEach(target => target.innerHTML = '');
    const type = e.params.payload.type;
    const targetDataAttr = 'sliderTarget';
    let slideImagesTemplate = null;
    let nextSibling = type === "shopImage" ? e.currentTarget.nextElementSibling : e.currentTarget.parentNode.nextElementSibling;
    // 直後に最初に現れるモーダル写真templateを検索
    while (nextSibling) {
      if (nextSibling.dataset[targetDataAttr] === "slideImages") {
        slideImagesTemplate = nextSibling;
        break;
      }
      nextSibling = type === "shopImage" ? nextSibling.nextElementSibling : nextSibling.parentNode.nextElementSibling;
    }
    const slideImages = slideImagesTemplate.content.querySelectorAll('img');
    const closeSlideImages = Array.from(slideImages).map(element => element.cloneNode(true));

    closeSlideImages.forEach(slideImage => {
      this.modalImageWrapperTargets.forEach(target => {
        const modalImage = this.#generateModalImage(slideImage);
        const cloneModalImage = modalImage.cloneNode(true);
        target.appendChild(cloneModalImage);
      });
    });

    // スライダーを生成
    this.#mainImageSlider();
    this.#spImageSlider();

    if (e.params.payload.number) {
      // 番号指定がある場合はその番号の画像がいきなり開くように
      const number = e.params.payload.number;
      this.mainSlider.slideTo(number, 0, false);
    }
    this.imageModalTarget.style.display = "block";
    this.imageModalTarget.animate([{ opacity: "0" }, { opacity: "1" }], 400);
    document.querySelector("body").style.overflow = "hidden";
  }

  // 写真モーダルを閉じる
  closeImageModal() {
    document.querySelector("body").style.overflow = "auto";
    this.imageModalTarget.animate([{ opacity: "1" }, { opacity: "0" }], 200).onfinish = () => {
      // フェードアウト完了後に実行
      this.imageModalTarget.style.display = "none";
    };
  }

  // 領域外クリックで写真モーダルを閉じる
  #closeImageModalOnExternalClick() {
    document.addEventListener("click", (e) => {
      // 領域内または開く動作のときは閉じない
      if (!e.target.closest('.js_visual_modal_body') && !e.target.closest(".js_imageBtn") && !e.target.closest(".sp_swiper_image")) {
        this.closeImageModal();
      }
    });
  }

  #generateModalImage(slideImage) {
    const clone = document.importNode(this.modalImageTemplateTarget.content, true);
    const slideElement = clone.querySelector('.swiper-slide');
    slideElement.appendChild(slideImage);

    return slideElement;
  }
}
