const MIN_ZOOM_LEVEL = 5;

const settingMap = (mapWrapperTarget, zoomLevel, defaultZoomLevel, center) => {
  const map = new google.maps.Map(mapWrapperTarget, {
    zoom: zoomLevel ? Number(zoomLevel) : defaultZoomLevel, // ズームレベルの指定
    minZoom: MIN_ZOOM_LEVEL, // ズームの最小値（引きの限界：日本列島が入る程度までにする）
    center,
    clickableIcons: false, // 地図上の他のアイコンクリック設定
    gestureHandling: "greedy", // スマホの地図ドラッグを一本指にする
    fullscreenControl: false, // 地図全画面表示ボタンを非表示
    mapTypeControl: false, // 地図・航空写真切り替えのボタンを非表示
    styles: [{
      featureType: 'poi',
      stylers: [
        { visibility: 'off' }, // デフォルトの地図上の他施設ピンを非表示にする
      ]
    }]
  });
  const transitLayer = new google.maps.TransitLayer();
  transitLayer.setMap(map);
  
  return map;
}

export { settingMap }
